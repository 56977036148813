import { useState, useEffect, useCallback }             from 'react';
import { LoadingIndicator }                             from  "../../../lib/iprs-react-library/src/index";
import { FormStateConsts }                              from '../Form/DataCapture';


/* load the detail of a specific website config from the API */
const loadSite = (doUpdate, websiteConfigID, websiteConfigVersionID, api, setConfigError, setConfigLoading) => {

    setConfigError('');
    setConfigLoading(true);

    let params = { 'WebsiteConfigID': websiteConfigID };
    if (websiteConfigVersionID) {
        params['WebsiteConfigVersionID'] = websiteConfigVersionID;
    }

    return api.transact('GetBookingSiteConfigDetail', params)
        .then(r => r.apiResult)
        .then(r => {
            setSite(doUpdate, r);
            setConfigError('');
            setConfigLoading(false);
        })
        .catch(e => {
            setConfigError('Error: ' + e.apiText);
            setConfigLoading(true);
            return console.log(JSON.stringify(e));
        });
}

const loadSiteList = (api, doUpdate, setConfigData, setConfigError, setConfigLoading) => {

    api.validateLoggedIn()
        .then(r => api.transact('GetBookingSiteConfigList', {}))
        .then(r => r.apiResult)
        .then(r => {

            setConfigData(r);
            setConfigLoading(false);

            if (r.WebsiteConfigs?.length) {
                // just load the first in the list to initialise
                loadSite(doUpdate, r.WebsiteConfigs[0].WebsiteConfigID, 0, api,
                    setConfigError, setConfigLoading);
            }
        })
        .catch(e => {
            api.setToken(null);
            doUpdate({
                error: 'Please try logging in',
                changePageState: FormStateConsts.Authenticating
            });
        })
}

const setSite = (doUpdate, websiteConfig) => {

    const fields = websiteConfig?.ReferralForm?.Fields;
    if (Array.isArray(fields)) {
        /* create a unique key which will be used by form designer to provide
         * a REACT key. The key needs to be stable accross drag and drop 
         * operations so generating on load is usefull. */
        const newFields = fields.map((f, i) => { return { ...f, uniqueID: i + 1 } });
        websiteConfig.ReferralForm.Fields = newFields;
    }

    doUpdate({
        websiteConfig,
        changePageState: FormStateConsts.Configuration
    });
}

const createSite = (api, doUpdate, title, setConfigError, setConfigData, setConfigLoading) => {
        setConfigError('');
        setConfigLoading(true);
        return api.transact("CreateBookingSiteConfig", { Title: title })
            .then(r => r.apiResult)
            .then(r => {
                setConfigLoading(false);

                setConfigData(r);
                setSite(doUpdate, r.NewConfig);
            })
            .then(() => setConfigError(''))
            .catch(e => setConfigError(e.apiText));
    };

const SiteConfigSelect = state => {

    const { websiteConfig,
        api,
        doUpdate,
        setConfigData,
        configData: { WebsiteConfigs } = {} } = state;

    const versions = websiteConfig?.OtherVersions;

    const selectedWebID         = websiteConfig?.WebsiteConfigID;
    const selectedWebVersion    = websiteConfig?.WebsiteConfigVersion;

    const [title, setTitle] = useState('');

    const [configLoading, setConfigLoading] = useState(false);
    const [configError, setConfigError] = useState(false);

    useEffect(() => loadSiteList(api, doUpdate, setConfigData, setConfigError, setConfigLoading), [api, doUpdate, setConfigData, setConfigError, setConfigLoading]);

    const handleSiteUpdate = useCallback(e => {
        e.preventDefault();
        loadSite(doUpdate, e.target.value, 0, api, setConfigError, setConfigLoading);
    }, [doUpdate, api]);

    const handleSiteVersionUpdate = useCallback(e => {
        e.preventDefault();
        loadSite(doUpdate, selectedWebID, e.target.value, api, setConfigError, setConfigLoading);
    }, [doUpdate, api, selectedWebID]);

    const handleCreateSite = useCallback(e => {
        e.preventDefault();
        createSite(api, doUpdate, title, setConfigError, setConfigData, setConfigLoading);
    }, [api, doUpdate, title, setConfigError, setConfigData, setConfigLoading]);

    return <form>
        <div className='form__field' >
            <label className="form__label" htmlFor='siteListSelect' >
                Select the site to configure
            </label>
            <div className='form__dropdown-wrapper form__field form__field--full-width' >
                <select className="form__dropdown" onChange={handleSiteUpdate} value={selectedWebID} name='siteListSelect' id='siteListSelect' >
                    {WebsiteConfigs?.map(s => <option key={s.WebsiteConfigID} value={s.WebsiteConfigID} className='form__mock-dropdown-option' >{s.Title}</option>)}
                </select>
            </div>
        </div>
        <div className='form__field' >
            <label className="form__label" htmlFor='siteListSelect' >
                Select a version
            </label>
            <div className='form__dropdown-wrapper form__field form__field--full-width' >
                <select className="form__dropdown" onChange={handleSiteVersionUpdate} value={selectedWebVersion} name='siteListSelect' id='siteListSelect' >
                    <option key={0} value={0} className='form__mock-dropdown-option' >{'Latest'}</option>
                    {versions?.map(v => <option key={v.VersionNumber} value={v.VersionNumber} className='form__mock-dropdown-option' >{v.VersionNumber} ({v.VersionDate})</option>)}
                </select>
            </div>
        </div>
        <div className='form__field' >
            <label className="form__label" htmlFor='title' >
                Add a new Booking site configuration
            </label>
            <input type='text' id='title' value={title} onChange={ e => setTitle( e.target.value ) } />
            <button onClick={handleCreateSite}>New</button>
        </div>

        <p>{configError}</p>
        <LoadingIndicator loading={configLoading} />

        </form>
}

export default SiteConfigSelect;