/** REACT component for designing / configuring a text field on a form. */
const DateBoxDesign = ({field, fieldOptions, updateField}) => {
    
    const maxDaysAhead      = Number.parseInt( field.maxDaysAhead?? 0 );
    const maxDaysPrevious   = Number.parseInt( field.maxDaysPrevious?? (120 * 365) );
    const pivotYAhead       = Number.parseInt( field.pivotYAhead?? 0 );
    
    const handleChange = e => {
        
        e.stopPropagation();
        
        const newField = {...field};
        if ('keyName' === e.target.name){
            newField.name = e.target.value;
        }else if ('label' === e.target.name){
            newField.text = e.target.value;
        }else { 
            if( e.target.type === 'checkbox' ){
                newField[e.target.name] = e.target.checked;
            }else{
                newField[e.target.name] = e.target.value;
            }
        }
        
        updateField( newField );
    }
    
    return <div>
    
        <div className={'form__field form__field--full-width'} >
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'label'}>
                Label
            </label>
            <input name='label' value={field.text} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
        </div>
        
        <div className={'form__field form__field--full-width'} >
            <label htmlFor='keyName'>Key Name</label>
            <select name='keyName' value={field.name} onChange={handleChange} >
            { fieldOptions
                    ?.filter( fo => fo.length? true: false )
                    ?.map( fo => fo[0] ) // text field has no sub fields
                    ?.sort( (fo1, fo2) => fo1.defaultText?.localeCompare( fo2.defaultText ) )
                    ?.map( fo => <option value={fo.key} >{fo.defaultText} ({fo.key})</option> )
            }
            </select>
        </div>
        {
            <div>
            <legend>You can limit the date range the user can enter. 
            Set Max Days Ahead to zero to limit to no later than today.
            To limit to no longer than 2 years in the last, set Max Days Previous
            to 730 (365*2). 
            Pivot Years Ahead determins how two digit years are interpreted.
            Set 100 for all these to be in the current century; set zero
            for DOB so 30 would mean 1930 rather than 2030 which is in the 
            future.</legend>
                <div className={'form__field form__field--full-width'} >
                    <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'maxDaysAhead'}>
                        Max Days Ahead (zero for DOB)
                    </label>
                    <input name='maxDaysAhead' type='number' value={maxDaysAhead} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
                </div>


                <div className={'form__field form__field--full-width'} >
                    <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'maxDaysPrevious'}>
                        Max Days Previous
                    </label>
                    <input name='maxDaysPrevious' type='number' value={maxDaysPrevious} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
                </div>
                
                <div className={'form__field form__field--full-width'} >
                    <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'pivotYAhead'}>
                        Pivot Years Ahead
                    </label>
                    <input name='pivotYAhead' type='number' value={pivotYAhead} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
                </div>
            </div>
        }
        
        <div className={'form__field form__field--full-width'} >
            <label htmlFor='required' >Required</label>
            <input name='required' checked={field.required} onChange={handleChange} type='checkbox' className="form__checkbox-input" />
        </div>
    </div>
};

export default DateBoxDesign;