import React, { useState } from "react";

import ButtonHolder from "../ButtonHolder";
import InjuryOptionList from "./InjuryOptionList";

/**
 *
 * @returns {undefined}
 */
const InjuryForm = ({
    count,
    injuryTypes,
    updateInjuries,
    cancel,
    captureComments
}) => {
    const [injuries, setInjuries] = useState(new Array(count).fill({}));

    const updateInjury = (index, injurySelected) => {
        let injuriesClone = injuries.slice();
        injuriesClone[index] = injurySelected;

        setInjuries(injuriesClone);
    };

    const onOK = () => updateInjuries(injuries);

    /* true if all the entries in 'injuries' array are non-zero */
    const okEnabled = injuries.reduce(
        (accumulator, currentValue) => accumulator && currentValue?.pkRegionId,
        true
    );

    const injuryCount = injuries.length;
    const title = injuryCount > 1 ? "Multiple Injury Areas?" : "Injury Area?";
    const subTitle =
        injuryCount > 1
            ? "Please enter multiple injury areas:"
            : "Please enter an injury area:";

    return (
        <div>
            <h2 className="content-boxes__box-content-title">
                {title}
            </h2>

            <p>{subTitle}</p>

            <div className="injury-areas__dropdowns">
                {injuries.map((v, i) => (
                    <InjuryOptionList
                        injuryTypes={injuryTypes}
                        index={i}
                        key={i}
                        onChange={updateInjury}
                        injuries={injuries}
                        captureComments={captureComments}
                    />
                ))}
            </div>

            <ButtonHolder
                action={onOK}
                cancel={cancel}
                actionTxt={"Confirm and continue"}
                cancelTxt={"Cancel and go back"}
                enabled={okEnabled}
            />
        </div>
    );
};

export default InjuryForm;
