import { useState } from "react";
import ButtonHolder from "../ButtonHolder";

const VisualChoice = ({ updateVisual, doCancel }) => {

    const [isVisualLocal, setisVisualLocal] = useState(null);

    const handleChange = e => setisVisualLocal('true' === e.target.value);

    const handleOK = e => updateVisual(isVisualLocal);

    return <div className="modal__outer">
        <div className="modal__inner form__checkbox">

            <h2 className='content-boxes__box-content-title'>How would you like your assessment?</h2>

            <fieldset className='content-boxes__optionset fieldset_margin' >
                <label htmlFor='tel' for='tel'
                    className="form__checkbox  reversed_colour" >Telephone Call
                    <input className="form__checkbox-input" id='tel'
                        type='radio' name='visual' value='false'
                        checked={false === isVisualLocal} onChange={handleChange} />
                    <span className='form__checkbox-custom' ></span>
                </label>

                <label className="form__checkbox  reversed_colour" htmlFor='vid' for='vid'>Video Call
                    <input className="form__checkbox-input" id='vid'
                        type='radio' name='visual' value='true'
                        checked={true === isVisualLocal} onChange={handleChange} />
                    <span className='form__checkbox-custom' ></span>
                </label>
            </fieldset>

            <p>If you choose a <strong>video call</strong> we will send a link
                that will be required for the call.</p>

            <ButtonHolder action={handleOK} cancel={doCancel}
                actionTxt={'Confirm and continue'}
                 enabled={null!=isVisualLocal} />

        </div>
    </div>
}

export default VisualChoice;