import React, { useState } from "react";

import Input from "../FormField";

const Address = props => {
    const {
        api,
        handleChange: parentHandleChange,
        validating: validate,
        values: fields = {},
        addressLine1 = {},
        addressLine2,
        addressLine3,
        town,
        county,
        postcode = {}
    } = props;

    const requiredNames = Object.entries(props)
        .filter(([k, f]) => f?.required)
        .map(([k, f]) => f?.name);

    /*
     * A list of addresses to present to the user to select
     */
    const [selection, setSelection] = useState(null);

    const [postcodeError, setPostcodeError] = useState(null);

    // fields have names given in the config so have to find the value
    const postcodeValue = fields[postcode.name] ?? "";
    const addressLookupBtnEnabled =
        postcodeValue.length > 0 && !(postcodeError?.length > 0);

    const lookupAddress = () => {
        const postcodeFieldName = postcode.name;
        const postcodeVal = fields[postcodeFieldName];

        if (postcodeVal?.length > 0) {
            api.addressLookup(postcodeVal)
                //.then( response => response.apiResult.sort( (a, b) => a.Address1 > b.Address1 ) )
                .then(response => {
                    setPostcodeError(null);
                    setSelection(response.apiResult);
                })
                .catch(err => setPostcodeError("Address not found"));
        }
    };

    const handleChange = (fieldName, value) => {
        if (fieldName === postcode.name) {
            /* reset the error so user can try looking up the new postCode */
            setPostcodeError(null);
        }

        parentHandleChange({
            target: { name: fieldName, value },
            preventDefault: () => {}
        });
    };

    const selectAddress = e => {
        e.preventDefault();

        const addressKey = e.target.id;

        const selectedAddress = selection.filter(
            a => addressKey === a.Address1
        )[0];

        const newFields = { ...fields };
        newFields[addressLine1.name] = selectedAddress?.Address1;
        newFields[addressLine2.name] = selectedAddress?.Address2;
        newFields[addressLine3.name] = selectedAddress?.Address3;
        newFields[county.name] = selectedAddress?.County;
        newFields[town.name] = selectedAddress?.Town;
        newFields[postcode.name] = selectedAddress?.Postcode;

        parentHandleChange({
            target: { name: "allFields", value: newFields },
            preventDefault: () => {}
        });

        setSelection(null);
    };

    const addressOrSelect = () => {
        if (selection) {
            return (
                <ul
                    style={{
                        marginLeft: "11em",
                        paddingBottom: "2em",
                        listStyle: "none",
                        height: "15em",
                        overflowY: "scroll"
                    }}
                >
                    {selection.map(s => (
                        <li key={s.Address1}>
                            <a
                                href="./"
                                id={s.Address1}
                                onClick={selectAddress}
                            >
                                {s.Address1}
                            </a>
                        </li>
                    ))}
                </ul>
            );
        } else {
            return (
                <>
                    <div class="form__field form__field--full-width">
                        <label htmlFor="addressLine1" className="form__label">
                            {addressLine1?.text}
                            {requiredNames.includes(addressLine1?.name) && (
                                <span style={{ color: "red" }}> *</span>
                            )}
                        </label>

                        <Input
                            name={addressLine1?.name}
                            type="text"
                            classNAme="form__input"
                            onChange={handleChange}
                            validate={validate}
                            requiredNames={requiredNames}
                            values={fields}
                        />
                    </div>

                    <div class="form__field form__field--full-width">
                        <label htmlFor="addressLine2" className="form__label">
                            {addressLine2?.text}
                            {requiredNames.includes(addressLine2?.name) && (
                                <span style={{ color: "red" }}> *</span>
                            )}
                        </label>

                        <Input
                            name={addressLine2?.name}
                            type="text"
                            className="form__input"
                            onChange={handleChange}
                            validate={validate}
                            requiredNames={requiredNames}
                            values={fields}
                        />
                    </div>

                    <div class="form__field form__field--full-width">
                        <label htmlFor="town" className="form__label">
                            {town?.text}
                            {requiredNames.includes(town?.name) && (
                                <span style={{ color: "red" }}> *</span>
                            )}
                        </label>

                        <Input
                            name={town?.name}
                            type="text"
                            className="form__input"
                            onChange={handleChange}
                            validate={validate}
                            requiredNames={requiredNames}
                            values={fields}
                        />
                    </div>

                    <div className="form__field form__field--full-width">
                        <label htmlFor="county" className="form__label">
                            {county?.text}
                            {requiredNames.includes(county?.name) && (
                                <span style={{ color: "red" }}> *</span>
                            )}
                        </label>

                        <Input
                            name={county?.name}
                            type="text"
                            className="form__input form__input--set-width"
                            onChange={handleChange}
                            validate={validate}
                            requiredNames={requiredNames}
                            values={fields}
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <div className="form__field form__field--full-width">
                <label htmlFor="postcode" className="form__label">
                    {postcode?.text}
                    {requiredNames.includes(postcode?.name) && (
                        <span style={{ color: "red" }}> *</span>
                    )}
                </label>

                <Input
                    name={postcode?.name}
                    type="text"
                    className="form__input form__input--set-width"
                    onChange={handleChange}
                    validate={validate}
                    requiredNames={requiredNames}
                    values={fields}
                />

                <button
                    disabled={!addressLookupBtnEnabled}
                    onClick={lookupAddress}
                    className="button button--postcode"
                >
                    {postcodeError ?? "Find Address"}
                </button>
            </div>

            {addressOrSelect()}
        </>
    );
};

export default Address;
