import React, {useState}                             from    'react';

/* 
 * React component to display a drop down box. Uses an HTML select.
 */
const DropDownList = props => {
    
    const {name, text, values, handleChange, width, Options: items = [],
        otherOption, validating, required } = props;
    
    const value = values[name];
    
    const [otherInputValue, setOtherInputValue] = useState('');
    
    const otherInputValueSet = otherInputValue? true: false;
    const otherOptionOn      = otherOption?     true: false;
    
    const showOtherInList    = otherOptionOn && otherInputValueSet && !(items.map( i => i.Value ).includes( value ));
    const showOtherInput     = value === '__other_special_value';
    
    
    const widthInt = Number.parseInt( width );
    let wrapperClass;
    if(100 <= widthInt && 200 > widthInt){
        wrapperClass = 'form__dropdown-wrapper form__dropdown-wrapper--width-100';
    }else{
        wrapperClass = 'form__dropdown-wrapper ';
    }
    
    let className = 'form__dropdown ';
    if(validating){
        if( required && (!value || value === '0') ){
            className += ' input-validate-highlight';
        }
    }
    
    return  <div> 
            <div className='form__field form__field--full-width' >
                <label className="form__label" htmlFor={name} >
                    {text}
                    {required && <span style={{color: 'red'}}> *</span>}
                </label>
                <div className={wrapperClass} >
                    <select className={className}
                            name={name} 
                            onChange={handleChange} 
                            value={value} >
                            
                        <option key={0} value={0} className='form__mock-dropdown-option' >{'Select'}</option>
                        { showOtherInList && <option key={otherInputValue} value={otherInputValue} className='form__mock-dropdown-option' >{otherInputValue}</option> }
                        { items.map(  i =>   <option key={i.Value} value={i.Value} className='form__mock-dropdown-option' >{i.Text}</option> )}
                        { otherOption &&     <option key='__other_special_value' value='__other_special_value' className='form__mock-dropdown-option' >{otherOption}</option> }
                    </select>
                </div>
            </div>
            <div className='form__field form__field--full-width' >
                {showOtherInput && <div className='form__field form__field--full-width' >
                    <label className="form__label" htmlFor={name} >
                        {otherOption}
                        {required && <span style={{color: 'red'}}> *</span>}
                    </label>
                    <input  name={name} 
                            value={otherInputValue} 
                            placeholder={'Enter ' + otherOption}
                            onChange={e=>setOtherInputValue(e.target.value)} 
                            onBlur={handleChange} />
                </div> }
            </div>
        </div>
}
 

export default DropDownList;
