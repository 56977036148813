import DataCapture, {
    FormStateConsts,
    websiteStages
} from "../FormDesigner/Form/DataCapture";
import wr527Fudges from "./wr527Fudges";

/**
 * The main processor of application state. See useReducer in the App and the REACT
 * docs for useReducer.
 * @param {Object} currentState The current application state
 * @param {Object} captured An update or state change action. Often captured data
 * from user input.
 * @returns {Object} The updated state
 */
const reducer = (currentState, captured) => {

    let newDC = { ...currentState };

    if (captured?.error) {
        newDC.error = captured.error;
    }
    if (captured?.clearError) {
        newDC.error = null;
    }

    if (captured.handleLoginEvent) {
        //setCapturedData( newDC );
        return newDC;
    }

    if (captured.websiteConfig) {
        updateWithConfig(captured.websiteConfig, newDC);
    }

    switch (newDC.pageState) {
        case FormStateConsts.Bootstraping:
            if (captured.error) {
                newDC.pageState = FormStateConsts.Error;
            } else if (1 < captured.websiteConfig.Clients?.length) {
                newDC.pageState = FormStateConsts.ClientCapture;
            } else if (
                newDC.appointmentType &&
                !newDC.captureInjuries &&
                !newDC?.websiteConfig?.CaptureVisual
            ) {
                newDC.pageState = FormStateConsts.TimeCapture;
            } else {
                newDC.pageState = FormStateConsts.AppointmentCapture;
            }

            break;
        case FormStateConsts.ClientCapture:
            newDC.Client = captured.Client;

            if (newDC.Client?.ClientPartyID) {
                newDC.ServiceActivities = filterServiceActivities(newDC.ServiceActivities, newDC.Client.ClientPartyID);
                if (newDC.ServiceActivities?.length > 1) {
                    newDC.pageState = FormStateConsts.ServiceCapture;
                } else{
                    newDC.activeStages = newDC.activeStages.filter(as => as[0] !== FormStateConsts.ServiceCapture);
                    if (
                        newDC.appointmentType &&
                        !newDC.captureInjuries &&
                        !newDC?.websiteConfig?.CaptureVisual
                    ) {
                        newDC.pageState = FormStateConsts.TimeCapture;
                    } else {
                        newDC.pageState = FormStateConsts.AppointmentCapture;
                    }
                } 
            }

            break;
        case FormStateConsts.ServiceCapture:
            newDC.serviceActivityId = captured.serviceActivityId;
            newDC.pageState = FormStateConsts.AppointmentCapture;
            break;
        case FormStateConsts.AppointmentCapture:
            newDC.appointmentType = captured.appointmentType;
            newDC.appointmentTypes = captured.appointmentTypes;
            newDC.injuries = captured.injuries;
            newDC.isVisual = captured.isVisual;

            if (newDC.appointmentType) {
                newDC.pageState = FormStateConsts.TimeCapture;
            } else {
                newDC.pageState = FormStateConsts.PersonCapture;
            }
            //setCapturedData( newDC );

            break;
        case FormStateConsts.TimeCapture:
            // this will have been signalled in holdBookingSlot
            if (captured.holdBookingID) {
                newDC.bookingId = captured.holdBookingID;
                if (captured.StartTime) {
                    newDC.startTime = captured.StartTime;
                    newDC.finishTime = captured.FinishTime;
                }

                if (newDC.caseID) {
                    newDC.pageState = FormStateConsts.Review;
                } else {
                    newDC.pageState = FormStateConsts.PersonCapture;
                }
            }

            break;
        case FormStateConsts.PersonCapture:
            // personal data not stored with rest of data
            //setPersonalData( captured )

            if (captured.personalData) {
                newDC.personalData = captured.personalData;
                newDC.pageState = FormStateConsts.Review;
            }

            //setCapturedData( newDC );

            break;
        case FormStateConsts.Review:
            if (captured?.doCancel) {
                // always go back to time (decision meeting 21-11-26)
                newDC.pageState = FormStateConsts.TimeCapture;
            } else if (captured.allDone) {
                // All done passes the caseRef
                newDC.caseRef = captured.CaseRef;
                newDC.caseID = captured.CaseID;

                // all done signalled from finaliseReferralAndBooking
                newDC.pageState = FormStateConsts.Finish;
            } else if (captured.error) {
                newDC.pageState = FormStateConsts.Error;
                newDC.error =
                    "We are very sorry. There was an error while saving your booking. Please contact IPRS on 0800 072 1227 selecting option 1.";
            }
            break;
        case FormStateConsts.Finish:
            newDC.pageState = FormStateConsts.AppointmentCapture;

            if (captured?.makeNewBooking) {
                /* Create a fresh state and run the whole bootstrapping process
                 * again. Better to do the whole process again and get a new
                 * guest token if necessary as they have a fixed time. */
                newDC = new DataCapture();
                newDC.api = currentState.api;
                newDC.configMode = currentState.configMode;
                /* setting this will trigger useEffect in the App */
                newDC.pageState = FormStateConsts.Bootstraping;
            }

            break;

        case FormStateConsts.Error:
            break;
        case FormStateConsts.Configuration:
        default:
    }

    if (captured.changePageState) {
        newDC.pageState = captured.changePageState;
    }

    return newDC;
};

const DEV_WEB_CONFIG_ID = 34566456;
const STAGING_WEB_CONFIG_ID = 34763845;

const updateWithConfig = (webConfig, newDC) => {

    if(DEV_WEB_CONFIG_ID === webConfig?.WebsiteConfigID || 
        STAGING_WEB_CONFIG_ID === webConfig?.WebsiteConfigID){
        newDC.websiteConfig = wr527Fudges(webConfig);
    }else{
        // pass the whole of the config to be stored on the app state
        newDC.websiteConfig = webConfig;
    }

    

    newDC.activeStages = [...websiteStages];
    if (newDC.configMode) {
        // Finish doesn't display in the progress bar in live site
        newDC.activeStages.push([FormStateConsts.Finish, "Finish"]);
        newDC.activeStages.push([
            FormStateConsts.Configuration,
            "Configuration"
        ]);
    }

    const Fields = webConfig?.ReferralForm?.Fields;

    if (Fields) {
        newDC.websiteConfig.ReferralForm = {};
        newDC.websiteConfig.ReferralForm.Fields = Fields.map((f, i) => {
            // make sure every field has a name and a lower case controlType
            const name = f.name ? f.name : "index_" + i;
            const controlTypeTmp = f.controlType
                ? f.controlType.toLowerCase()
                : "text";
            const controlType =
                "textbox" === controlTypeTmp ? "text" : controlTypeTmp;
            const required =
                "true" === f.required || true === f.required ? true : false;

            const fieldCopy = { ...f, name, controlType, required };

            if ("dropdownlist" === controlType) {
                const options = f.Options ? f.Options : f.Item;

                fieldCopy.Options = options ? options : [];
            }

            return fieldCopy;
        });
    } else {
        newDC.websiteConfig.ReferralForm = null;
    }

    const patient = webConfig.Patient;
    newDC.personalData = {
        ...patient,
        DOB: patient?.BirthDate ?? null,
        personTitle: patient?.PersonalTitle ?? null,
        Forename: patient?.FirstName ?? null,
        Surname: patient?.LastName ?? null
    };

    if (!newDC.Client) {
        if (1 === webConfig?.Clients?.length) {
            newDC.Client = webConfig.Clients[0];
        } else {
            newDC.Client = {};
        }
    }
    if (!newDC?.Client?.ClientPartyID) {
        newDC.Client.ClientPartyID = webConfig.ClientPartyID;
    }

    newDC.serviceActivityId = webConfig.ServiceActivityId;
    newDC.serviceId = webConfig?.ServiceId;

    newDC.captureInjuries = webConfig.CaptureInjuries;
    newDC.ServiceActivities = Array.isArray(newDC.websiteConfig?.ServiceActivities) ?
        [...newDC.websiteConfig.ServiceActivities] : [];

    newDC.appointmentTypes = webConfig.AppointmentTypes;
    if (1 === webConfig.AppointmentTypes?.length) {
        newDC.appointmentType = newDC.appointmentTypes[0];
        if (!webConfig.CaptureInjuries && !webConfig?.CaptureVisual) {
            newDC.activeStages = newDC.activeStages.filter(
                s => s[0] !== FormStateConsts.AppointmentCapture
            );
        }
    }

    if (webConfig.CaseID) {
        newDC.caseID = webConfig.CaseID;
        newDC.activeStages = newDC.activeStages.filter(
            s => s[0] !== FormStateConsts.PersonCapture
        );
    } else if (!newDC.websiteConfig.ReferralForm && !newDC.configMode) {
        newDC.pageState = FormStateConsts.Error;
        newDC.error = "You have arrived at this site through an invalid link.";
    }
    if (2 > webConfig.Clients?.length) {
        newDC.activeStages = newDC.activeStages.filter(
            s => s[0] !== FormStateConsts.ClientCapture
        );
    }
    if (2 > webConfig.ServiceActivities?.length) {
        newDC.activeStages = newDC.activeStages.filter(
            s => s[0] !== FormStateConsts.ServiceActivities
        );
    }
    newDC.Clients = webConfig.Clients;
    newDC.captureInjuries = webConfig.CaptureInjuries;
    newDC.serviceActivityId = webConfig.ServiceActivityId;

    if (!webConfig.Token && !newDC.configMode) {
        newDC.pageState = FormStateConsts.Error;
        newDC.error = "Please use the booking link sent to you by email.";
    }
};

/** return just service activities for the clientID */
const filterServiceActivities = (serviceActivities, clientID) => {
    if( !clientID ){
        return serviceActivities;
    }else{
        return serviceActivities.filter(sa => Array.isArray( sa.ClientPartyIDs )? sa.ClientPartyIDs.includes(clientID) : true);       
    }   
}

export default reducer;
