

import InjuryForm                   from    './InjuryForm'
import InjuryCount                  from    './InjuryCount'

import React, {useState, useEffect} from 'react'

const InjuryCapture = ({update, cancel, api, captureComments, maxInjuries = 3}) => {
    
    //captures the count selected by the user
    const [count, setCount]             = useState(maxInjuries > 1 ? 0 : 1);

    const [injuryTypes, setInjuryTypes] = useState([]);
    
    const [error, setError]             = useState(null)
    
    useEffect( () => {
        
        api.injuryRegionList()
        .then(r => r.apiResult)
        .then(apiResult => {
            setInjuryTypes(apiResult)
        })
        .catch( error => {
            setError( error.userErrorMessage )
        })
    }, [api])
    
    const updateCount = (count) => {
        setCount(count)
    } 
    
    const content = () => {
        
        if( error ){
            return <p>{error}</p>
        }else if( count ){
            if( 0 === injuryTypes.length ){
                return <p>{'Loading'}</p>
            }else{
                return (<InjuryForm count={count} injuryTypes={injuryTypes} 
                            updateInjuries={update} cancel={cancel} injuriesCaptured 
                            captureComments={captureComments} />)
            }
        }else{
            return (<InjuryCount updateCount={updateCount} cancel={cancel} maxInjuries={maxInjuries} />)
        }
    }
    
    return (
    
        <div className="modal__outer">
            <div className="modal__inner">
            
            { content() }
            
            </div>
        </div>
    )
}

export default InjuryCapture