import { ButtonHolder } from  "../../lib/iprs-react-library/src/index";
import React, { useEffect, useState } from "react";
import DataCapture from "../FormDesigner/Form/DataCapture";

const PUBLIC_URL = process.env.PUBLIC_URL;

const ServiceActivitySelection = ({ update, appState }) => {
    const clientID = appState?.Client?.ClientPartyID;

    const serviceActivities = appState?.websiteConfig?.ServiceActivities;
    const filteredServiceActivities = serviceActivities?.filter(sa =>
        sa.ClientPartyIDs.includes(clientID)
    );

    const [selectedServiceActivityID, setSelectedServiceActivityID] =
        useState(0);

    useEffect(() => {
        if (0 === filteredServiceActivities?.length) {
            const serviceActivityOb = new DataCapture();
            serviceActivityOb.serviceActivityId = 0;
            serviceActivityOb.ServiceActivities = [];
            update(serviceActivityOb);
        }
    }, [filteredServiceActivities, update]);


    const doAction = () => {
        
        const serviceActivityOb = new DataCapture();
        serviceActivityOb.serviceActivityId = selectedServiceActivityID;
        update(serviceActivityOb);
    };
    const buttonEnabled = 0 !== selectedServiceActivityID;

    const handleClick = e => {
        const atInt = parseInt(e.target.value);
        setSelectedServiceActivityID(atInt);
    };

    return (
        <div>
            <h1>Select Service</h1>

            <form>
                <ul className="appointment-types" onChange={handleClick}>
                    {filteredServiceActivities?.map(sa => (
                        <li
                            key={sa.ServiceActivityID}
                            style={{ display: "contents" }}
                        >
                            <ServiceActivityOption
                                serviceActivity={sa}
                                selected={
                                    sa.ServiceActivityID ===
                                    selectedServiceActivityID
                                }
                                setSelectedServiceActivityID={
                                    selectedServiceActivityID
                                }
                            />
                        </li>
                    ))}
                </ul>
            </form>

            <ButtonHolder
                action={doAction}
                actionTxt={"Select"}
                enabled={buttonEnabled}
            />
        </div>
    );
};

const ServiceActivityOption = ({ serviceActivity, selected }) => {
    const img = selected ? (
        <img
            className="appointment-types__type-checked"
            src={PUBLIC_URL + "/images/check.svg"}
            alt="checked"
        />
    ) : (
        ""
    );

    return (
        <>
            <input
                className="appointment-types__type-input"
                type="radio"
                id={serviceActivity.ServiceActivityID}
                name="serviceActivity"
                value={serviceActivity.ServiceActivityID}
            />
            <label
                htmlFor={serviceActivity.ServiceActivityID}
                className="appointment-types__type"
            >
                <span className="appointment-types__type-content">
                    <span className="h2 appointment-types__type-content-title">
                        {serviceActivity.FriendlyName}
                    </span>
                </span>

                <span
                    className="appointment-types__type-checkbox"
                    style={{ opacity: selected ? 1 : 0 }}
                >
                    {img}
                </span>
            </label>
        </>
    );
};

export default ServiceActivitySelection;
