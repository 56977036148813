/* 
 * Function to save a referral.
 */
const saveReferral = (api, appState) => {
    
    const { Client: { ClientPartyID }, serviceActivityId, personalData,
    injuries } = appState;
    
    // the API uses specific keys
    const injuriesMapped = injuries?.map( i => { return { Comments: i.comment, InjuryRegionId: i.pkRegionId } } );
    
    const referralData = { ...personalData, InjuryList: injuriesMapped };
    
    return api.saveReferralForm(ClientPartyID, serviceActivityId, referralData);
}

export default saveReferral;