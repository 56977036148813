
import React, {useState}     from    'react';

import Input                            from    '../FormField';


const today         = new Date();
const thisFullY     = today.getFullYear();
const thisYear      = thisFullY % 100;
const thisCent      = Math.floor( thisFullY / 100 );
const maxPersonAge  = 120;

// internally store as dd/mm/yyyy
const parseDate = dateSt => {
    const [year = '', month = '', day = ''] = dateSt.split('-');
    
    return { day, month, year };
}

const defaultMaxDaysPrevious = Math.floor( maxPersonAge * 356.25 );
const defaultMaxDaysAhead    = 0;

const dateAdd = ( date, offsetDays ) => new Date( date.getTime() + (offsetDays * 24 * 60 * 60 * 1000) );

const limit = (val, min, max) => {
        
    const intVal = Number.parseInt( val, 10 );

    let result = '';
    if(intVal){
        result = intVal >= min && intVal <= max? intVal: '';
    }

    return result;
};

const interpretFields = (fName, fValue, dateFields, pivot) => {
    
    let {day = '', month = '', year = ''} = dateFields;
        
    const val = fValue

    switch (fName){
        case 'day':
            day  = limit( val, 0, 31 );
            break
        case 'month':
            month = limit( val, 1, 12 );
            break
        case 'year':

            const parsedYear = Number.parseInt( val, 10 );
            if( isNaN( parsedYear ) ){
                year = '';
            }else if ( parsedYear <= pivot ){
                year = thisCent * 100 + Math.max(0, parsedYear);
            }else if ( parsedYear < 100 ){
                year = ( thisCent - 1 ) * 100 + parsedYear;
            }else{
                year = parsedYear;
            }
            break;
        default:
            break;
    }
    
    return { day, month, year };
};

/* 
 * Component to collect a person's date of birth
 * Note this component stores it's state locally and only submits changed values
 * on blur.
 */
const DateTextBox = (props) => {
    
    const { name, text, handleChange: parentHandleChange, validating: validate, 
            required, values, 
            maxDaysAhead        = defaultMaxDaysAhead, 
            maxDaysPrevious     = defaultMaxDaysPrevious,
            pivotYAhead         = 0
        } = props;
    
    
    const dobRequired = required? ['year', 'month', 'day']: []
    
    const parentValueSt   =   values[name]? values[name]: '';
    const parentValue     =   parseDate( parentValueSt );
    
    const [value, setValue]           = useState( parentValue );
    
    const pivot           = thisYear + Number.parseInt( pivotYAhead, 10 );;
    
    const maxDate         = dateAdd( today, maxDaysAhead);
    const minDate         = dateAdd( today, -maxDaysPrevious );
    
    const [error,setError] = useState(null);
    
    const parentHandleChangeLocal = fields => {
        const {day, month, year} = fields;
        const dateSt =  year + 
                        '-' + 
                        (''+month).padStart(2,'0') + 
                        '-' + 
                        (''+day).padStart(2,'0');
        parentHandleChange( {target: {name, value: dateSt }, preventDefault: ()=>{} } );
    }
    
    const handleChange  = (fName, fValue) => {
        
        const newValue = {...value}
        newValue[fName] = fValue
        setValue( newValue );
    };
    
    const handleBlur = (fName, fValue) => {
        
        // limit days to 31 etc
        const { day, month, year } = interpretFields( fName, fValue, value, pivot );
            
        let goodValue = false;
        let newVal;
        if( day && year && month ){
            const dt        = new Date( year, month - 1, day );
            if ( dt.getTime() < minDate.getTime() ){
                setError( 'Earliest date is ' + minDate.toLocaleDateString('en-GB', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) );
            }else if ( dt.getTime() > maxDate.getTime() ){
                setError( 'Latest date possible is ' + maxDate.toLocaleDateString('en-GB', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) );
            }else{
                setError( '' );
                goodValue = true;
            }
            newVal    = {year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate()}
        }else{
            setError( '' );
            newVal    = { day: day, month: month, year: year} 
        }
        
        setValue( newVal );
        
        if( goodValue ){
            parentHandleChangeLocal( newVal );
        }else{
            // make sure we clear any previous good value which would get sumitted
            parentHandleChange( {target: {name, value: null }, preventDefault: ()=>{} } );
        }
    }
    
    return (
        <div  >
            <div className="form__field form__field--full-width">
                <label className="form__label">
                    {text}
                    {required && <span style={{color: 'red'}}> *</span>}
                </label>

                <div className="form__date-wrapper">
                    <label htmlFor="day" className="form__label form__label--auto-width">Day</label>
                    <Input className='form__input form__input--2-chars'
                        name="day" onBlur={handleBlur} onChange={handleChange} validate={validate} 
                        maxlength="2" placeholder=""
                        requiredNames={dobRequired}  values={value} 
                         />

                    <label htmlFor="month" className="form__label form__label--auto-width">Month</label>
                    <Input className='form__input form__input--2-chars'
                        name="month" onBlur={handleBlur} onChange={handleChange} validate={validate} 
                        maxlength="2" placeholder=""
                        requiredNames={dobRequired}  values={value} 
                         />

                    <label htmlFor="year" className="form__label form__label--auto-width">Year</label>
                    <Input className='form__input form__input--4-chars'
                        name="year" onBlur={handleBlur} onChange={handleChange} validate={validate} 
                        maxlength="4" placeholder=""
                        requiredNames={dobRequired}  values={value} 
                         />
                </div>
            </div>
            <div className="form__field form__field--full-width">
                <div className="form__label "></div>
                <div style={{ color: 'red', width: '100%' }}>{error}</div>
            </div>
        </div>
    )
}

export default DateTextBox;


