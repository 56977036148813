import { LoadingIndicator } from "../../lib/iprs-react-library/src/index";

import { FormStateConsts } from "../FormDesigner/Form/DataCapture";
import MessageBox from "../MessageBox";

import ClientCapture from "../ClientCapture/ClientCapture";
import AppointmentCapture from "../AppointmentCapture/AppointmentCapture";
import TimeCapture from "../TimeCapture/TimeCapture";
import PersonCapture from "../PersonCapture/PersonCapture";
import Review from "../Review/Review";
import Finish from "../Finish/Finish";
import SiteConfigForm from "../FormDesigner/SiteConfigForm/SiteConfigForm";
import ServiceActivitySelection from "../ServiceActivity/ServiceActivitySelection";

/**
 * REACT component that renders the app. The idea is to separate the logic
 * from the rendering.
 * @param {function} doUpdate
 *                      A function that gets passed down to sub components for
 *                      them to change the application state.
 * @param {object} api  The API. Some data from the API only belongs in a small
 *                      part of the application tree and so sub components are
 *                      able to use the IPRS API directly.
 * @param {object} personalData
 *                      Data captured about the patient. As this identifies a
 *                      person, it is not stored in application storage.
 * @param {int} pageState
 *                      A value representing the current application stage.
 *                      See FormStateConsts.
 *
 *
 * @returns {pageState@var;body@var;api|String}
 */
const APPRender = ({ doUpdate, api, appState, setReferralForm = null }) => {
    const { websiteConfig = {}, personalData = {}, designMode } = appState;

    /*
     * Select a component to display, depending on where we are in the process
     */
    let body = "init";
    switch (appState.pageState) {
        case FormStateConsts.Authenticating:
            body = <p>Logging in</p>;
            break;
        case FormStateConsts.ClientCapture:
            body = (
                <ClientCapture
                    doUpdate={doUpdate}
                    api={api}
                    capturedData={appState}
                />
            );
            break;
        case FormStateConsts.AppointmentCapture:
            body = (
                <AppointmentCapture
                    doUpdate={doUpdate}
                    api={api}
                    appState={appState}
                />
            );
            break;
        case FormStateConsts.ServiceCapture:
            body = (
                <ServiceActivitySelection
                    update={doUpdate}
                    api={api}
                    appState={appState}
                />
            );
            break
        case FormStateConsts.TimeCapture:
            body = (
                <TimeCapture
                    doUpdate={doUpdate}
                    api={api}
                    appState={appState}
                />
            );
            break;
        case FormStateConsts.PersonCapture:
            body = (
                <PersonCapture
                    doUpdate={doUpdate}
                    api={api}
                    appState={appState}
                    designMode={designMode}
                />
            );
            break;
        case FormStateConsts.Review:
            body = (
                <Review
                    doUpdate={doUpdate}
                    appState={appState}
                    personalData={personalData}
                />
            );
            break;
        case FormStateConsts.Finish:
            body = <Finish api={api} doUpdate={doUpdate} appState={appState} />;
            break;

        case FormStateConsts.Bootstraping:
            body = <LoadingIndicator loading={true} />;
            break;
        case FormStateConsts.Configuration:
            body = (
                <SiteConfigForm
                    {...{ ...appState, websiteConfig, doUpdate, api }}
                />
            );
            break;
        case FormStateConsts.Error:
        default:
            body = <MessageBox>{appState.error}</MessageBox>;
    }

    return <div>{body}</div>;
};

export default APPRender;
