import React, { useState } from 'react';

const MultiCheckbox = props => {

    const { name: fieldName, values, handleChange: parentChange = e => null, Options: items = [],
        otherOption } = props;

    // values is all the values for all fields on the form
    const value = values[fieldName]?? '';

    /* store the values locally so we don't have to make an update to the 
     * parent state for every keypress on the Other box*/
    const [localValues, setLocalValues] = useState(value.split(';').map(v => v.trim()));

    /* The names (not label text ) of all checkboxes */
    const keyNames = items.reduce((prev, curr) => [...prev, curr.Value], []);
    /* the value in the Other box */
    const otherVal = localValues.find(v => !keyNames.includes(v));

    const handleChange = e => {

        const { target: { name, checked, value, type } } = e;
        
        const localValuesCopy = [...localValues];
        let newLocalValues = localValuesCopy.filter(v => keyNames.includes(v));

        if ('checkbox' === type) {
            if (checked) {
                newLocalValues.push(name);
            } else {
                newLocalValues = newLocalValues.filter(v => v !== name);
            }
            serializeAndSignal(newLocalValues, parentChange, fieldName);
        } else if ( value ) {

            newLocalValues.push( value )
        }

        setLocalValues(newLocalValues);
    }

    const handleBlur = e => serializeAndSignal(localValues, parentChange, fieldName);

    return <>
        {items.map((item, i) => <InputCheckbox key={i} {...{ ...item, handleChange, checkedValues: localValues }} />)}
        {otherOption && <div className='form__field form__field--full-width' >
            <label className="form__label" htmlFor={'__other_value__'} >
                        {otherOption}
                    </label>
                    <input name={'__other_value__'}
                        value={otherVal}
                        onChange={handleChange}
                        onBlur={handleBlur} />
            </div>}
    </>
}

const InputCheckbox = props => {

    const { Value, Text, checkedValues, handleChange } = props;

    const checked = checkedValues?.includes( Value );

    return <div className='form__field form__field--full-width' >
        {<div className='form__field form__field--full-width' >
            <label className="form__label" htmlFor={Value} >
                 { Text }
            </label>
            <input
                type={'checkbox'}
                name={Value}
                value={Value}
                checked={checked}
                onChange={handleChange} />
        </div>}
    </div>
}

const serializeAndSignal = (localValues, parentChange, fieldName)  => {
    const serializedValue = localValues.reduce((prev, curr) => {
        const filtered = curr.replaceAll(';', ' ');
        return prev ? prev + '; ' + filtered : filtered;
    }, '');

    parentChange({ preventDefault: () => null, target: { name: fieldName, value: serializedValue } });
}

export default MultiCheckbox;