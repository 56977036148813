import React from 'react'

/**
 * Provides links/buttons, one do take some action and the other to cancel/go 
 * back. There are a couple of places where this pattern shows up in the diary
 * machup from naked marketing and this component more or less replicates that.
 * @param {function} action Callback when the user clicks the action
 * @param {type} cancel Callback for when the user clicks cancel
 * @param {type} Descriptive text for the action
 * @param {type} Descriptive text for cancel
 * @param {boolean} Enabled/Disabled 
 * @param {ref} parentRef A ref created with UseRef which is used to pass focus to the button
 * @returns {JSX}  
 */
const ButtonHolder = ({action, cancel, actionTxt, cancelTxt, enabled, parentRef}) => {
    
    const handleActionClick = e => {
        e.preventDefault()
        if(enabled) {
            action()
        }
    }
    const handleCancelClick = e => {
        e.preventDefault()
        cancel()
    }
    
    return (
        
        <div className="button-holder" >
            <a href="." onClick={handleCancelClick}   title="{cancelTxt}" className="button-holder__go-back">
                {cancelTxt}
            </a>

            <a ref={parentRef} href="." onClick={handleActionClick} title={actionTxt} className="button button--extra-wide"
               style={{backgroundColor: enabled? 'var(--colour__primary)': 'grey'}} >
                {actionTxt}
            </a>
        </div>
    )
}

export default ButtonHolder 