import React, {useState}                        from 'react';
import {PlusButton}                             from  "../../lib/iprs-react-library/src/index";

import {TypeSelect}                             from './DesignField'; 

/* 
 * A component to display a button for adding a new input to a form
 */

const NewInput = ({addField}) => {
    
    const [field, setField]                 = useState({ controlType: 'text',
                                                         name: 'newField',
                                                         text: 'Change this'});
    
    const handlClick = e => {

        e.preventDefault();

        const newField = {...field};
        // make sure this is unique as duplicates cause DND problems
        const intID      = Math.floor(Math.random() * 1000000);
        newField.uniqueID = intID;
        newField.name     = 'newField_' + intID;
        addField( newField );
    }
    
    return <div>
            <TypeSelect updateField={setField} field={field} />
            <PlusButton onClick={handlClick} />
        </div>
} 

export default NewInput;

