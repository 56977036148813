import React, { useCallback, useState } from "react";
import ButtonHolder from "../ButtonHolder";
import MessageBox from "../MessageBox";
import { getImageForAppTypeURL } from "../AppointmentCapture/AppTypeList";
import { TwoSwitch, LoadingIndicator } from  "../../lib/iprs-react-library/src/index";

import finaliseReferralAndBooking from "../App/finaliseBooking";

/**
 * REACT component to render a form to review a booking before submitting. */
const Review = ({ doUpdate, appState }) => {
    const {
        websiteConfig: {
            ShowPatientReview,
            ShowNotificationOptions,
            ShowAppTypeReview,
            LocationText
        },
        personalData: {
            DOB,
            personTitle,
            Forename: firstName,
            Surname: lastName
        } = {},
        appointmentType = {}
    } = appState;

    const bookingMade = 0 < Number.parseInt(appState.bookingId);
    const confirmText = bookingMade ? "Confirm your booking" : "Confirm your Referral";

    const DOBDt = Date.parse(DOB);
    const DOBSt = isNaN(DOBDt)
        ? "Invalid Date"
        : new Intl.DateTimeFormat("en-GB", { dateStyle: "long" }).format(DOBDt);
    
    /* turn on if it's configured or if didn't make a booking (and there's a valid DOB).
    The latter added to override the setting where we are just making a referral but no 
    booking */
    const showPatientReview = ShowPatientReview || (!isNaN(DOBDt) && !bookingMade);

    // flag true when we are processing
    const [loading, setLoading] = useState(false);

    const { startTime } = appState;

    const {
        Description: appDesc,
        Title: appTitle,
        ImageURL: appImage
    } = appointmentType ? appointmentType : {};

    const appTypeIcon = getImageForAppTypeURL(appImage);

    const handleCancel = useCallback(() => {
        doUpdate({ doCancel: true });
    }, [doUpdate]);

    const handleOK = () => {
        // Make it imposible to clcik twice
        if (!loading) {
            setLoading(true);
            finaliseReferralAndBooking(appState)
            .then( r => {
                doUpdate( { allDone: true, CaseRef: r.CaseRef, CaseID: r.CaseID } );
            } )
            .catch( e => {
                doUpdate( { error: 'Error saving booking'} );
            });
        }
    };

    return (
        <>

            {/* <pre>bookingID {JSON.stringify(appState.bookingId, null, 2)}</pre>
            <pre>booking made{JSON.stringify(bookingMade, null, 2)}</pre>
            <pre>{JSON.stringify(appState, null, 2)}</pre> */}

            <TwoSwitch test={showPatientReview || bookingMade}>
                <>
                    <h2 className="center">One last thing</h2>
                    <p>Confirm the below details and we'll do the rest</p>
                </>
            </TwoSwitch>

            <TwoSwitch test={bookingMade}>
            <TwoSwitch test={LocationText}>
                <MessageBox title="Where" image={appTypeIcon}>
                    <p className="content-boxes__box-content-text">
                        {LocationText}
                    </p>
                </MessageBox>
            </TwoSwitch>

            <TwoSwitch test={ShowAppTypeReview}>
                <MessageBox title="What" image={appTypeIcon}>
                    <strong>{appTitle}</strong>
                    <br />
                    {appDesc}
                </MessageBox>
            </TwoSwitch>

            <MessageBox title="When" image={appTypeIcon}>
                <p class="content-boxes__box-content-text">
                    <strong>
                        {new Intl.DateTimeFormat("en-GB", {
                            dateStyle: "full",
                            timeStyle: "short"
                        }).format(startTime)}
                    </strong>
                    <br />
                    <TwoSwitch test={ShowNotificationOptions}>
                        <span className="content-boxes__optionset">
                            <label className="form__checkbox" for="email">
                                Email
                                <input
                                    id="email"
                                    className="form__checkbox-input"
                                    type="checkbox"
                                    name="email"
                                    checked={true}
                                />
                                <span className="form__checkbox-custom"></span>
                            </label>

                            <label class="form__checkbox" for="sms">
                                SMS
                                <input
                                    id="sms"
                                    className="form__checkbox-input"
                                    type="checkbox"
                                    name="sms"
                                    checked={true}
                                />
                                <span className="form__checkbox-custom"></span>
                            </label>
                        </span>
                    </TwoSwitch>
                </p>
            </MessageBox>

            </TwoSwitch>

            <TwoSwitch test={showPatientReview}>
                <MessageBox title="Who" image={appTypeIcon}>
                    <p class="content-boxes__box-content-text">
                        Patient Name:{" "}
                        <strong>
                            {personTitle} {firstName} {lastName}
                        </strong>
                        <br />
                        Date of birth: <strong>{DOBSt}</strong>
                    </p>
                </MessageBox>
            </TwoSwitch>

            <ButtonHolder
                action={handleOK}
                cancel={handleCancel}
                actionTxt={confirmText}
                cancelTxt={"Edit your booking"}
                enabled={!loading}
            />
            <LoadingIndicator loading={loading} />
        </>
    );
};

export default Review;
