import React, {useCallback}             from 'react';
import {DragDropContext, Droppable}    
                                        from 'react-beautiful-dnd';
import NewInput                         from './NewInput';
import DesignField                      from './DesignField';


/* 
 * React component for form designing.
 */
const FormDesigner = (state) => {
    
    const {
        websiteConfig,
        doUpdate,
        validating,
        values}                                 = state;
    const {ReferralForm = []}                = websiteConfig;
    
    const fields = ReferralForm?.Fields?.filter( f=>f.name )?? [];

    const setReferralFields = useCallback((refFormFields) => {

        const newRefForm = { ...ReferralForm }
        newRefForm["Fields"] = refFormFields;

        const newWebsiteConfig = { ...websiteConfig, ReferralForm: newRefForm };

        doUpdate({ websiteConfig: newWebsiteConfig });

    }, [ReferralForm, websiteConfig, doUpdate]);

    const onDragEnd = dragResult => {
        
        const {destination, source} = dragResult;
        if(!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        
        const field = fields[source.index];
        const newField = {...field};
        
        const newFields = [...fields];
        newFields.splice(source.index, 1);
        newFields.splice(destination.index, 0, newField);
        
        setReferralFields( newFields );
    }
    
    /* change the configuration of a field */
    const updateField = (field, index, del) => {
        
        const newFields = [...fields];
        if(del){
            newFields.splice(index, 1);
        }else{
            newFields.splice(index, 1, field);
        }

        const dataSources =
            newFields.reduce((prev, curr) => {
                const newVal = [...prev];
                if (curr.DataSourceID) {
                    newVal.push(curr.DataSourceID);
                }
                return newVal;
            }, []);

        const newWebsiteConfig = {
            ...websiteConfig,
            ReferralForm: { Fields: newFields },
            DataSources: dataSources
        };

        doUpdate({ websiteConfig: newWebsiteConfig });
    }
    
    const addField = field => {
        
        const newFields = [...fields, field];
        setReferralFields( newFields );
    }
    
    return <div>
        
        <DragDropContext onDragEnd={onDragEnd} >
            <Fields fields={fields} websiteConfig={websiteConfig} 
            validating={validating} values={values} updateField={updateField} 
            addField={addField} />
        </DragDropContext>
    </div>;
}

const Fields = ({fields, websiteConfig, api, validating, values, updateField, addField}) => {
    
    /* note that the uniqueKey field is added in processDesigner on loading the 
     * form. It wouldn't be present necessarily when rendering for display.*/
    
    return <div>
        <Droppable droppableId={'myDroppable'} >
            {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps} >
                    {fields
                        .map( (field,index) => {
                            return <DesignField key={field.uniqueID} field={field} index={index} 
                            websiteConfig={websiteConfig} validating={validating} values={values} 
                            updateField={updateField} />
                        } )
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
        <NewInput addField={addField} />
    </div>;
}



export default FormDesigner;

