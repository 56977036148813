/*
 * Module to display a list of Appointment Types. Exports only APPTypeList 
 * component. 
 */
import React, {useState}                                    from 'react';

import ButtonHolder                     from    '../ButtonHolder';
import DataCapture                      from '../FormDesigner/Form/DataCapture';

// the base hosting URL of the site. CSS and images are relative
const PUBLIC_URL=process.env.PUBLIC_URL;

const ClientCapture = ({capturedData, doUpdate}) => {
    
    const [clientID, setClientID]                   = useState(0)
    
    const { websiteConfig: { Clients: clients } }   = capturedData;
    
    const doAction = () => {
        //find the appointment data (description etc)
        const client = clients
            .filter( t => t.ClientPartyID === clientID )[0]
        
        const clientOb                 = new DataCapture()
        clientOb.Client                = client
        
        doUpdate( clientOb )
    }
    
    const buttonEnabled = clientID;
    
    const handleChange = client => {  
        setClientID(client);
    }
    
    return (
        <>
            <h2 className="center">
                Select a customer or scheme name
            </h2>
            <ClientList setClient={handleChange} clientID={clientID} 
                clients={clients} />
            <ButtonHolder action={doAction}  actionTxt={'Select'}
                 enabled={buttonEnabled} />
        </>
    )
}

/**
 * Component to show a list of appointment types with icons and an checkbox 
 * input field to allow the user to select 
 * @returns {JSX}
 */
const ClientList = ({clientID, setClient, clients}) => {
    
    const types = clients.map( client => {
        
        const id = Number.parseInt(client.ClientPartyID);
        const selected = clientID === id;
        return (<ClientItem key={id} id={id} 
            title={client.Name}  selected={selected} />) 
        }
    )
    
    const handleClick = e => {
        
        const atInt = parseInt(e.target.value)
        setClient( atInt )
    }
    
    return (
        <div className="appointment-types" onChange={handleClick} >
            {types}
        </div>
    )
}

/*
 * Component to represent a single type in the select list
 */
const ClientItem = ({id, title, selected}) => {

    const img = selected? (<img className="appointment-types__type-checked" src={PUBLIC_URL + '/images/check.svg'} alt="checked"/>): ''
    
    const idSt = 'appointment-type-' + id
    
    //const IconSVG = getImageForAppTypeURL( icon );
    
    return (
        <>
            <input 
                    className="appointment-types__type-input" 
                    type="radio" 
                    name="appointment-type"
                    value={id}
                    id={idSt} />
            <label className="appointment-types__type" htmlFor={idSt} >
                

                <span className="appointment-types__type-content">
                        <span className="h2 appointment-types__type-content-title">
                            {title}
                        </span>

                        
                    </span>

                <span className="appointment-types__type-checkbox" style={{opacity: selected? 1: 0}} >
                    {img}
                </span>
            </label> 
        </>
    )
};

export default ClientCapture;
