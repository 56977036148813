/*
 * Module to display a list of Appointment Types. Exports only APPTypeList 
 * component. 
 */
import React                                    from 'react'

import { ReactComponent as musculoskeletal }    
             from './appointment_type_images/musculoskeletal.svg'
import { ReactComponent as mental_health   }    
            from './appointment_type_images/mental_health.svg'
import { ReactComponent as physiotherapy   }    
            from './appointment_type_images/physiotherapy.svg'
import { ReactComponent as desk_screen   }    
            from './appointment_type_images/desk_screen.svg'
import { ReactComponent as case_management   }    
            from './appointment_type_images/case_management.svg'
import { ReactComponent as functional_capacity   }    
            from './appointment_type_images/functional_capacity.svg'
import { ReactComponent as management_referral   }    
            from './appointment_type_images/management_referral.svg'
import { ReactComponent as musculoskeletal_digital_triage   }    
            from './appointment_type_images/musculoskeletal_digital_triage.svg'
import { ReactComponent as vehicle_assesment   }    
            from './appointment_type_images/vehicle_assesment.svg'
import { ReactComponent as wellness_health   }    
            from './appointment_type_images/wellness_health.svg'
import { ReactComponent as workshop   }    
            from './appointment_type_images/workshop.svg'


// the base hosting URL of the site. CSS and images are relative
const PUBLIC_URL=process.env.PUBLIC_URL;

/**
 * Component to show a list of appointment types with icons and an checkbox 
 * input field to allow the user to select 
 * @returns {JSX}
 */
const APPTypeList = ({appointmentType, setAppointmentType, appointmentTypes}) => {
    
    const types = appointmentTypes.map( appType => {
        
        const id = Number.parseInt(appType.AppointmentTypeID)
        return (<APPType key={id} id={id} 
            title={appType.Title} description={appType.Description} 
            icon={appType.ImageURL} setAppointmentType={setAppointmentType}
            appointmentType={appointmentType}/>) 
        }
    )
    
    const handleClick = e => {
        
        const atInt = parseInt(e.target.value)
        setAppointmentType( atInt )
    }
    
    return (
        <div className="appointment-types" onChange={handleClick} >
            {types}
        </div>
    )
}

/*
 * Component to represent a single type in the select list
 */
const APPType = ({id, title, description, icon, appointmentType}) => {
    
    
    
    const selected = id === appointmentType
    const img = selected? (<img className="appointment-types__type-checked" src={PUBLIC_URL + '/images/check.svg'} alt="checked"/>): ''
    
    const idSt = 'appointment-type-' + id
    
    const IconSVG = getImageForAppTypeURL( icon );
    
    return (
        <>
            <input 
                    className="appointment-types__type-input" 
                    type="radio" 
                    name="appointment-type"
                    value={id}
                    id={idSt} />
            <label className="appointment-types__type" htmlFor={idSt} >
                <span className="appointment-types__type-image-wrapper">
                    <IconSVG />
                </span>

                <span className="appointment-types__type-content">
                        <span className="h2 appointment-types__type-content-title">
                            {title}
                        </span>

                        <span className="appointment-types__type-content-text">
                            {description}
                        </span>
                    </span>

                <span className="appointment-types__type-checkbox" style={{opacity: selected? 1: 0}} >
                    {img}
                </span>
            </label> 
        </>
    )
};

/** Appointment types have a URL field which we use to determin which image
* to display next to the appointment type in lists and on the review page 
* @returns an SVG image object
*/
const getImageForAppTypeURL = imageURL => {

    let IconSVG = null
    switch (imageURL){
        case 'mental_health': 
            IconSVG = mental_health;
            break;
        case 'physiotherapy': 
            IconSVG = physiotherapy;
            break;
        case 'desk_screen': 
            IconSVG = desk_screen;
            break;;
        case 'case_management': 
            IconSVG = case_management;
            break;
        case 'functional_capacity': 
            IconSVG = functional_capacity;
            break;
        case 'management_referral': 
            IconSVG = management_referral;
            break;
        case 'musculoskeletal_digital_triage': 
            IconSVG = musculoskeletal_digital_triage;
            break;
        case 'vehicle_assesment': 
            IconSVG = vehicle_assesment;
            break;
        case 'wellness_health': 
            IconSVG = wellness_health;
            break;
        case 'workshop': 
            IconSVG = workshop;
            break;
        case 'musculoskeletal':
        default:   
            IconSVG = musculoskeletal; 
    }
    
    return IconSVG;
}

export {getImageForAppTypeURL};
export default APPTypeList;
