import React from 'react'

const Slot = ({select, slot, amPmFilter, selected}) => {
    
    const startTime     = slot.StartTime;
    const slotTime      = startTime;
    const slotHour      = slotTime.getHours()
    const slotHour12    = slotHour > 12? slotHour - 12: slotHour
    const amPm          = slotHour > 11? 'pm': 'am'
    const min           = slotTime.getMinutes()
    const minSt         = min? ':' + min: ':00';
    
    let className       = selected? 'time-select__time-input time-select__button--active':
            'time-select__time-input'
    
    const clickHandler = (e) => {
        
        e.preventDefault()
        
        select( [startTime, slot.FinishTime] );
    }

    if (amPmFilter !== 'all' && amPmFilter !== amPm){
        return ''
    }else{
        return(
            <label  className="time-select__time" htmlFor={startTime} >
                <input  className={className} 
                    type="radio" name="time-select" id={startTime} 
                    value={startTime} checked={selected}  />

                <span onClick={clickHandler} className="time-select__time-content">{slotHour12}{minSt}<span className="time-select__time-meridiem"> {amPm}</span></span>
            </label>
        )
    }
}

/*
 * Component to show a list of days, which display as a column with the day
 * at the top and a list of time slots. There's also a filter for am / pm. 
 */
const DayDiary = ({select, date, daySlots, amPmFilter, captured}) => {
    
   
    const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const day = days[ date.getDay() ]
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec']
    const month = months[ date.getMonth() ]
    const dom   = date.getDate()
    
    return (
        <div className="time-select__date">
            <h3 className="time-select__date-title"><span className="time-select__date-day">{day}</span> {dom} {month}</h3>

            {daySlots.map( s => <Slot slot={s} amPmFilter={amPmFilter} 
            select={select} selected={captured&&(captured[0]===s.StartTime)} key={s.StartTime.getTime() } /> )}
        </div>
    )
}

export default DayDiary