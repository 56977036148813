
import React from 'react'


const TimeForwardBackwards = ({weekChange}) => {
    
    const weekHandler = (e) => {
        
        e.preventDefault()
        const forwardBackward = e.target.name === 'forward'? true: false
        
        weekChange( forwardBackward )
    }
    
    return (
    
        
        
        <div className="time-select__prev-next">
            <button className="time-select__button time-select__button--larger-font" 
            onClick={weekHandler} name="backward" >
                {'<'}
            </button>

            <button className="time-select__button time-select__button--larger-font"
                onClick={weekHandler} name="forward" >
                {'>'}
            </button>
        </div>
    )
}

export default TimeForwardBackwards