import React                from 'react';

import DateTextBox          from './Fields/DateTextBox';
import DateTextBoxDesign    from './Fields/DateTextBoxDesign';
import DropDownList         from './Fields/DropDownList';
import DropDownListDesign   from './Fields/DropDownListDesign';
import Address              from './Fields/AddressCapture';
import AddressDesign        from './Fields/AddressCaptureDesign';
import SectionBreak         from './Fields/SectionBreak';
import TextBox              from './Fields/TextBox';
import TextBoxDesign        from './Fields/TextBoxDesign';
import Heading              from './Fields/Heading';
import HeadingDesign        from './Fields/HeadingDesign';
import TwoTextBox           from './Fields/TwoTextBox';
import TwoTextBoxDesign     from './Fields/TwoTextBoxDesign';
import InfoBox              from './Fields/InfoBox';
import InfoBoxDesign        from './Fields/InfoBoxDesign';
import MultiCheckbox        from './Fields/MultiCheckbox';


/* 
 * REACT component to display a list of form fields, to make a form
 */
const Form = ({ appState, values, handleChange, api, validating}) => {

    const { websiteConfig: { ReferralForm, DataSourceValues }, Client } = appState;

    const dataSources = DataSourceValues[Client?.ClientPartyID];

    const components = ReferralForm?.Fields
        ?.map(f => {

            let options = dataSources?.[f.DataSourceID]?? f.Options;

            return React.createElement(fieldToComponent(f), { ...f, Options: options, handleChange, api, values, validating });
        });
    
    return <>{components}</>
}

const fieldComponents = {
    
    address:        [Address,       AddressDesign],
    heading:        [Heading,       HeadingDesign],
    twotextbox:     [TwoTextBox,    TwoTextBoxDesign],
    dropdownlist:   [DropDownList,  DropDownListDesign],
    multiselect:    [MultiCheckbox, DropDownListDesign],
    datetextbox:    [DateTextBox,   DateTextBoxDesign],
    sectionbreak:   [SectionBreak,  'div'],
    'break':        [SectionBreak,  'div'],
    text:           [TextBox,       TextBoxDesign],
    checkbox:       [TextBox,       TextBoxDesign],
    email:          [TextBox,       TextBoxDesign],
    tel:            [TextBox,       TextBoxDesign],
    info:           [InfoBox,       InfoBoxDesign]
}

/**
* Gets a field object for the typename.
*/
const fieldToComponent = field => {
    
    const { controlType = '' } = field;
    
    const component = fieldComponents[ controlType ]?.[0] ?? TextBox;
    
    return component;
}

const fieldToDesignComponent = field => {

    const { controlType = '' } = field;
    
    const component = fieldComponents[ controlType ]?.[1] ?? TextBoxDesign;
    
    return component;
}

/* Validate a group filed such as address or two text box */
const validateGroupField = ( field, values ) => {

    const requiredNames = 
            Object.entries( field )
            .filter( ([k, f]) => f?.required )
            .map( ([k, f]) => f?.name );
    
    const valid = requiredNames.reduce( (accumulator, currentValue) => accumulator && values[ currentValue ], true );
    
    return valid? true: false;
}

/* validate a value against a regex which may be null. Null regex always passes */
const validateRegex = ( regex, value ) => {
    
    let result = false;
    if ( regex ){
        try{
            const regExp = new RegExp( regex );
            if( value && regExp.test( value ) ){
                result = true;
            }
        }catch(e){
            console.log( 'Bad regular expression \'' + regex + '\'' );
        }
    }else { 
        result = true;
    }
    
    return result;
}

/* validate a single field such as */
const validateSingleField = ( field, values ) => {

    const thisValue = values[ field.name ];
    let valid = false;
    if( validateRegex( field.regex, thisValue ) ){
        
        valid = !field.required || thisValue;
    }
    
    return valid? true: false;
}

/* validates whether a field is is currently valid (i.e the user has filled it 
in */
const validate = ( field, values ) => {

    let validator = ( field, values ) => true;
    
    if( 'address' === field.controlType || 'twotextbox' === field.controlType ){
        validator = validateGroupField;
    }else if( ['text', 'dropdownlist', 'checkbox', 'email', 'tel', 'datetextbox'].includes( field.controlType ) ){
        validator = validateSingleField;
    }
    
    const valid = validator( field, values );
    
    return valid;
}

export {fieldToComponent, fieldToDesignComponent, validate};
export default Form;