import saveReferral                     from '../PersonCapture/Referral';

/* 
 * function to save the referral form and finalise the booking.
 */
const finaliseReferralAndBooking = appState => {
    
    const {api}                 = appState;
    
    const initialCaseID         = appState.caseID?? appState.caseID;

    const referralPromise= appState?.websiteConfig.ReferralForm?
        saveReferral(api, appState).then( r => r.apiResult ):
        Promise.resolve({CaseID: initialCaseID, CaseRef: 'No referral form'});

    const bookingID = appState.bookingId?? 0;

    /* if we have a bookingID then we are making a booking. 
    Otherwise we do nothing and just return the referral promise
    result.
    */
    const getBookingPromise = ({CaseID, CaseRef})  => bookingID > 0 ?
        api.transact("FinaliseBookingSlot", {
            BookingID:      bookingID,
            CaseID:         CaseID,
            Description:    'Website booking',
            IsVisual:       appState.isVisual
        })
        .then( r => r.apiResult )
        .then( r => ({ ...r, CaseID }) ):
        Promise.resolve({ CaseID, CaseRef });

    /* this is ugly but basically we sometimes are making a referral and sometimes not.
    * sometimes we make a booking and sometimes not. When making a booking we need to
    * pass through the CaseID. The booking process will lookup the caseref from the caseid
    * so we always get this. We always should do at least a referral or a booking so we
    * always get a caseRef. 
    */
    return referralPromise
    .then( r => { 
        return getBookingPromise({ CaseID: r.CaseID, CaseRef: r.CaseRef });
    })
}

export default finaliseReferralAndBooking;
