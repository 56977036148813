import React, { useCallback } from "react";

import {
    TwoSwitch,
    CustomerCaseFeedbackStars as FeedbackStars
} from "../../lib/iprs-react-library/src/index";
import ButtonHolder from "../ButtonHolder";
import { ReactComponent as IPRS_symbol_bgwhite } from "./IPRS_symbol_bgwhite.svg";
import SelfReferralLink from "./SelfReferralLink";

const playstoreURL = process.env.REACT_APP_IPRS_APP_PLAY_STORE_URL;
const applestoreURL = process.env.REACT_APP_IPRS_APP_APPLE_STORE_URL;
const portalURL = process.env.REACT_APP_IPRS_PORTAL;

const Finish = ({ api, appState, doUpdate }) => {
    const {
        websiteConfig: {
            ShowBookAnotherLink = false,
            ShowAddToDiaryLink = false,
            ShowGetDirectionsLink = false,
            ShowAppstoreLinks = false,
            ShowPortalLinks = false,
            ShowFeedbackStars = false,
            ConfirmationText
        } = {}
    } = appState;

    const bookingMade = 0 < Number.parseInt(appState.bookingId);

    const buttonMessage = ShowBookAnotherLink
        ? "Book another Appointment"
        : "Close this window";

    const handleOK = useCallback(() => {
        if (ShowBookAnotherLink) {
            doUpdate({ makeNewBooking: true });
        } else {
            window.close();
        }
    }, [ShowBookAnotherLink, doUpdate]);

    return (
        <>
            <h2 className="center">
                Great news, your{" "}
                {bookingMade
                    ? "appointment has been booked"
                    : "referral has been made"}
            </h2>

            <p className="center">{ConfirmationText}</p>

            {appState.caseRef && (
                <p className="center">
                    Your case reference is: {appState.caseRef}
                </p>
            )}

            <div className="center">
                <TwoSwitch test={ShowAddToDiaryLink}>
                    <a
                        href="#"
                        title="Add to diary"
                        className="button button--inline"
                    >
                        Add to diary
                    </a>
                </TwoSwitch>

                <TwoSwitch test={ShowGetDirectionsLink}>
                    <a
                        href="#"
                        title="Get Directions"
                        className="button button--inline"
                    >
                        Get Directions
                    </a>
                </TwoSwitch>
            </div>

            <div className="content-boxes">
                <TwoSwitch test={ShowPortalLinks}>
                    <div className="content-boxes__box content-boxes__box--max-494">
                        <span className="content-boxes__box-image-wrapper">
                            <IPRS_symbol_bgwhite />
                        </span>

                        <div className="content-boxes__box-content">
                            <h2 className="content-boxes__box-content-title">
                                Start your recovery
                            </h2>

                            <p className="content-boxes__box-content-text">
                                <strong>
                                    Experience shows that the earlier you start
                                    your rehabilitation journey, the quicker you
                                    recover.
                                </strong>
                                <br />
                                <br />
                                <a
                                    href={portalURL}
                                    title="Click here for the free online portal"
                                    className="content-boxes__link"
                                >
                                    <strong>
                                        Our free online portal; has a range of
                                        videos and downloads to help manage your
                                        injury/condition &raquo;
                                    </strong>
                                </a>
                            </p>
                        </div>
                    </div>
                </TwoSwitch>

                <TwoSwitch test={ShowAppstoreLinks}>
                    <div className="center">
                        <p>Download the IPRS Health app here</p>

                        <a
                            className="content__app-store-link"
                            target="_blank"
                            href={playstoreURL}
                            title="Android App on Google Play"
                            rel="noreferrer"
                        >
                            <img
                                src="/images/google-play.svg"
                                alt="Android App on Google Play"
                            />
                        </a>

                        <a
                            className="content__app-store-link"
                            target="_blank"
                            href={applestoreURL}
                            title="IOS App on App Store"
                            rel="noreferrer"
                        >
                            <img
                                src="/images/app-store.svg"
                                alt="IOS App on App Store"
                            />
                        </a>
                    </div>
                </TwoSwitch>

                <SelfReferralLink api={api} caseID={appState.caseID} />

                <TwoSwitch test={ShowFeedbackStars}>
                    <FeedbackStars
                        api={appState.api}
                        caseID={appState.caseID}
                    />
                </TwoSwitch>
            </div>

            <ButtonHolder
                action={handleOK}
                actionTxt={buttonMessage}
                enabled={true}
            />
        </>
    );
};

export default Finish;
