import React, {useState}        from 'react';

/** REACT component for designing / configuring a text field on a form. */
const TextBoxDesign = ({field, fieldOptions, updateField}) => {
    
    const [regexError, setRegexError] = useState(null);
    
    const handleChange = e => {
        
        e.stopPropagation();
        
        const newField = {...field};
        if ('keyName' === e.target.name){
            newField.name = e.target.value;
        }else if ('label' === e.target.name){
            newField.text = e.target.value;
        }else { 
            if( e.target.hasOwnProperty('checked') ){
                newField[e.target.name] = e.target.checked;
            }else{
                newField[e.target.name] = e.target.value;
            }
        }
        
        updateField( newField );
    }
    
    const cols = Math.max( Number.parseInt( field.cols?? 20 ), 1 );
    const rows = Math.max( Number.parseInt( field.rows?? 1  ), 1 );
    
    // check the field when the user leaves it
    const handleBlur = e => {
        setRegexError( validateRegex( e.target.value )? '': 'Invalid Pattern' );
    }
    
    return <div>
    
        <div className={'form__field form__field--full-width'} >
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'label'}>
                Label
            </label>
            <input name='label' value={field.text} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
        </div>
        
        <div className={'form__field form__field--full-width'} >
            <label htmlFor='keyName'>Key Name</label>
            <select name='keyName' value={field.name} onChange={handleChange} >
            { fieldOptions
                    ?.filter( fo => fo.length? true: false )
                    ?.map( fo => fo[0] ) // text field has no sub fields
                    ?.sort( (fo1, fo2) => fo1.defaultText?.localeCompare( fo2.defaultText ) )
                    ?.map( fo => <option value={fo.key} >{fo.defaultText} ({fo.key})</option> )
            }
            </select>
        </div>
        
        <div>
            <div className={'form__field form__field--full-width'} >
                <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'rows'}>
                    Rows
                </label>
                <input name='rows' type='number' value={rows} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
            </div>

            <div className={'form__field form__field--full-width'} >
                <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'cols'}>
                    Columns
                </label>
                <input name='cols' type='number' value={cols} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
            </div>
        </div>
        
        <div>
            <legend>If you want specific validation of a field, set a pattern
            to validate against and a message to appear if the field doen't 
            match the pattern.
            Patterns should be valid Javacript regular expressions.</legend>
            <div className='form__field form__field--full-width' >
                <label htmlFor='regex' >Pattern</label>
                <input name='regex' onBlur={handleBlur} value={field.regex} onChange={handleChange} type='text' className="form__checkbox-input" />
            </div>
            <div className="form__field form__field--full-width">
                <div className="form__label "></div>
                <div style={{ color: 'red', width: '100%' }}>{regexError}</div>
            </div>
            
            <div className='form__field form__field--full-width' >
                <label htmlFor='validationWarning' >Pattern Error</label>
                <input name='validationWarning' value={field.validationWarning} onChange={handleChange} type='text' className="form__checkbox-input" />
            </div>
        </div>
        
        <div className={'form__field form__field--full-width'} >
            <label htmlFor='required' >Required</label>
            <input name='required' checked={field.required} onChange={handleChange} type='checkbox' className="form__checkbox-input" />
        </div>
    </div>
};

/* need to check that a regex compiles, otherwise the user will not be able 
 * to tell that they*/
const validateRegex = regex => {
    
    let valid = false;
    try{
        new RegExp( regex );
        valid = true;
    }catch(e){ }
    
    return valid;
}

export default TextBoxDesign;