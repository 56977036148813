/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


const HeadingDesign = ({field, updateField}) => {
    
    const handleChange = e => {
        
        e.stopPropagation();
        
        const newField = {...field};
        newField[e.target.name] = e.target.value;
        
        updateField( newField );
    }
    
    return <div>
        <select name='level' value={field.level} onChange={handleChange} >
            <option value='2'>Level 2</option>
            <option value='3'>Level 3</option>
        </select>
        <div className={'form__field form__field--full-width'} >
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'text'}>
                Heading text
            </label>
            <input name='text' value={field.text} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
        </div>
        <div className={'form__field form__field--full-width'} >
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'label'}>
                Sub-heading text
            </label>
            <input name='subheading' value={field.subheading} onChange={handleChange} className='form__input form__input form__input--mobile-y-bottom'/>
        </div>
    </div>
}

export default HeadingDesign;