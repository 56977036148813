import { useState } from "react";

import { formValidate as  validate, Form } from  "../../lib/iprs-react-library/src/index";
import FormDesigner from "../FormDesigner/FormDesigner";

import ButtonHolder from "../ButtonHolder";

import { TwoSwitch } from  "../../lib/iprs-react-library/src/index";

const PersonCapture = ({ doUpdate, api, appState, designMode }) => {
    //const referralForm = testForm;
    const { websiteConfig, personalData = {} } = appState;
    const { ReferralForm: referralForm } = websiteConfig;

    /* these are field values */
    const [fields, setFields] = useState(personalData);
    // true when user presses submit with unfilled fields which need higlighting
    const [validating, setValidating] = useState(false);
    //true when the form is untouched
    const [enabled, setEnabled] = useState(
        Object.entries(personalData)?.length > 0
    );

    const [designing, setDesigning] = useState(designMode);

    const doAction = () => {
        /* the fields variable contains user entered values. This just checks
         * that all fields have a value or are not required. The validate function
         * treats multi fields like address differently */
        let formComplete = referralForm?.Fields?.reduce(
            (accumulator, currentValue) =>
                accumulator && validate(currentValue, fields),
            true
        );

        if (formComplete) {
            doUpdate({ personalData: fields });
        } else {
            setValidating(true);
        }
    };

    const handleChange = e => {

        const { name, value, checked, type, noFormEnable = false } = e.target;

        if ("allFields" === name) {
            // we are setting multiple fields at the same time
            const clone = { ...fields, ...value };
            setFields(clone);
            setEnabled(true);
        } else if ("checkbox" === type) {
            setFieldVal(name, checked);
            if (!noFormEnable) {
                setEnabled(true); // allow fields to auto set to a value
            }
        } else {
            setFieldVal(name, value);
            setEnabled(true);
        }
    };

    const setFieldVal = (name, value) => {
        const clone = { ...fields };
        clone[name] = value;
        setFields(clone);
    };

    return (
        <>
            <div className="form">
                {designMode && (
                    <div className="form__field form__field--full-width">
                        <label className="form__label">Form Editing</label>
                        <input
                            type="checkbox"
                            checked={designing}
                            onChange={e => setDesigning(e.target.checked)}
                        />
                    </div>
                )}
                <TwoSwitch test={designing && designMode}>
                    <FormDesigner
                        {...{
                            ...appState,
                            doUpdate,
                            validating,
                            values: fields
                        }}
                    />
                    <Form
                        appState={appState}
                        values={fields}
                        handleChange={handleChange}
                        api={api}
                        validating={validating}
                    />
                </TwoSwitch>
            </div>

            <ButtonHolder
                action={doAction}
                actionTxt={"Submit"}
                enabled={enabled}
            />

            {validating ? (
                <h2>Please fill in the fields highlighted in red.</h2>
            ) : (
                ""
            )}
        </>
    );
};

export default PersonCapture;
