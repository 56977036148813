import React                                        from 'react';

import {BiErrorAlt}                                 from 'react-icons/bi';

const MessageBox = (props) => {

    const image = props.image? props.image: null;

return (<div class="content-boxes">

    <div class="content-boxes__box">
        <span class="content-boxes__box-image-wrapper">
            {image? React.createElement(image): <ErrIcon/>}
        </span>

        <div class="content-boxes__box-content">
            <h2 class="content-boxes__box-content-title">
                {props.title? props.title: 'Error'}
            </h2>

            <p class="content-boxes__box-content-text">
                {props.children? props.children: 'Sorry the site is having problems. Please try later.'}
            </p>
        </div>
    </div>
    </div>);
};

const ErrIcon = () => {
    
    const elemProps = {
        style: {color: 'var(--colour__secondary)',
            padding: '8%',
            display: 'block'
        }, 
        size: '100%',
    };
    
    return <>{React.createElement(BiErrorAlt, elemProps)}</>
}

export default MessageBox;

