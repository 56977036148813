import React, {useState}                               from    'react';

/* 
 * React component to display a simple edit box or input field. This also supports
 * checkboxes.
 */
const TextBox = (props) => {
    
    const {name, text, enabled, cols: colsSt = 20, rows: rowsSt=1, controlType, 
        validating, handleChange: parentHandleChange, values, required,
        regex, validationWarning } = props;
    
    const value = values[name];
    const isCheckbox = 'checkbox' === controlType.toLowerCase();
    
    const enabledProp = 'true' === enabled || true === enabled;
    
    const cols = Math.max( Number.parseInt( colsSt ), 1 );
    const rows = Math.max( Number.parseInt( rowsSt ), 1 );
    const multiline = rows > 1;
    
    const [error, setError] = useState(null);
    
    let className;
    if( isCheckbox ){
        className = 'form__checkbox';
    }else {
        className = multiline? 'form__textarea': 'form__input';
    }
    if(validating){
        if( required && !validate( regex, value ) ){
            className += ' input-validate-highlight';
        }
    }
    
    if(isCheckbox && undefined === value){
        /* for checkboxes we always submit a value wheras the other fields 
         * will simply not return anything. The reason is that people don't
         * expect to have to set and then clear the box just to send a false
         * value. This doesn't cause a render cycle as we only do this when 
         * undefined.
         * Mark this as noFormEnable as we haven't typed anything */
        if(parentHandleChange){
            parentHandleChange( { target: { name, checked: false, noFormEnable: true, 
                    type: 'checkbox' }, preventDefault: ()=>null } );
        }
    }
    
    const handleBlur = e => {
        
        const valid = validate( regex, value );
        
        setError( valid? null: validationWarning );
    }
    
    const boxProps = {name, value, enabled: enabledProp, className, 
        onChange: parentHandleChange, rows, cols, onBlur: handleBlur };
    
    if( isCheckbox ){
        boxProps.type = 'checkbox';
    }else{
        boxProps.type = 'text';
    }
    
    return <div>
        <div className="form__field form__field--full-width" >
            <label htmlFor={name} className="form__label">
                {text}
                {required && <span style={{color: 'red'}}> *</span>}
            </label>

            {React.createElement(
                multiline? 'textarea': 'input',
                boxProps)}
        </div>
        <div className="form__field form__field--full-width">
            <div className="form__label "></div>
            <div style={{ color: 'red', width: '100%' }}>{error}</div>
        </div>
    </div>
};

const validate = ( regex, value ) => {
    
    let result = false;
    if ( regex ){
        try{
            const regExp = new RegExp( regex );
            if( value && regExp.test( value ) ){
                result = true;
            }
        }catch(e){
            console.log( 'Bad regular expression \'' + regex + '\'' );
        }
    }else if( value ){ 
        result = true;
    }
    
    return result;
}

export default TextBox;

