import React                                from 'react';
import {TwoSwitch}         from  "../../lib/iprs-react-library/src/index";

/**
 * REACT component to display progress through the booking process.
 */
const FormProgress = ({progress, activeStages, handleClick = null}) => {
    
    const progLen = 1 + activeStages.findIndex( s => s[0] === progress );
    const progressClassName = 'progress__bar progress__bar--' + (progLen*20);
   
    return (
        <>
            <div className="background__grey">
                <ol className="progress__stages" >
                    {activeStages.map( s => <Stage {...{key: s[0], value: s[0], title: s[1], isActive: s[0]===progress, handleClick }} /> )}
                </ol>

                <div className={progressClassName} ></div>
            </div>
        </>
    );
}

const Stage = ({title, value, isActive, handleClick = null}) => {

    const className = 'progress__stage' + (isActive? ' progress__stage--active': '');
    
    const clickable = null != handleClick;
    
    return <li className={className} >
        <TwoSwitch test={clickable} >
            <a href='.' name={value}  onClick={handleClick} >{title}</a>
            <>{title}</>
        </TwoSwitch>
    </li>
}

export default FormProgress