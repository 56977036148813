import React, {useState}                            from 'react';
import {PlusButton, MinusButton}                    from  "../../../../lib/iprs-react-library/src/index";


/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

const InfoBoxDesign = ({field, updateField}) => {
    
    const { subFields = [] } = field;
    
    const [newType, setNewType]     =   useState('span');
    
    const handleChange = (index, subField) => {
        
        const newSubFields = [ ...subFields ];
        newSubFields.splice(index, 1, {...subField} );
        
        const newField = {...field, subFields: newSubFields};
        updateField( newField );
    }
    
    const handleAdd = e => {
        
        e.preventDefault();
        
        const newSubField = { text: 'Change Me', type: newType };
        
        const newField              = {...field, subFields: [...subFields, newSubField]};
        updateField( newField );
    }
    
    const handleDelete = (index) => {
        
        const newField              = {...field};
        const newSubFields            = [...subFields];
        newSubFields.splice(index, 1);
        
        newField.subFields            = newSubFields;
        
        updateField( newField );
    }
    
    return <div>
        <ul>
            {subFields.map( (subField, index) => <SubField {...{key: index, index, subField, handleDelete, handleChange } } /> )}
        </ul>
        <div>
            <select name='newType' value={newType} onChange={e => setNewType(e.target.value) } >
                { types.map( t => <option key={t[0]} value={t[0]} >{t[1]}</option> ) }
            </select>
            <div>
                <PlusButton onClick={handleAdd} />
            </div>
        </div>
    </div>
}

const types = [
    ['span',    'Text'],
    ['li', 'List Item'],
    ['a', 'Link'],
    ['strong', 'bold']
];

const SubField = ({ subField, index, handleDelete, handleChange }) => {
    
    return <li style={{listStyle: 'none'}} >
        <div>
            <div className={'form__field form__field--full-width'} >
                {
                    renderField( subField, index, handleChange )
                }
                <MinusButton onClick={e=>handleDelete(index)} />
            </div>
        </div>
    </li>
}

const renderField = (subField, index, onChange) => {
    
    const handleChange = e => {
        
        const newSubField = { ...subField };
        newSubField[e.target.name]  = e.target.value; 
        
        onChange( index, newSubField );
    }
    
    let fieldContent = null;
    let preContent   = null;
    switch (subField.type){
        case 'endPara':
            fieldContent = <div>&#x204B;</div>
            break;
        case 'a':
            fieldContent = <div>
                <input value={subField.text} onChange={handleChange} name='text' />
                <input value={subField.href} onChange={handleChange} name='href' />
            </div>
            break;
        case 'li':
            preContent = '.';
            fieldContent = <textarea value={subField.text} onChange={handleChange} name='text' />;
            break
        default:
            fieldContent = <textarea value={subField.text} onChange={handleChange} name='text' />
    };
    
    return <div>
        <div>{preContent}</div>
        {fieldContent}
    </div>
} 

export default InfoBoxDesign;

