import React, {useState, useEffect, useRef} from 'react';

import TimeAmPmFilter                       from './TimeAmPmFilter'
import TimeForwardBackward                  from './TimeForwardBackward'
import DayDiary                             from './DayDiary'
import DataCapture, {FormStateConsts}                
                                            from '../FormDesigner/Form/DataCapture';
//import {getWeekStartEnd}            from '../shared/DateUtil';
import ButtonHolder                         from    '../ButtonHolder';
import holdBookingSlot                      from '../App/holdBookingSlot';

// Temporal replaces legacy DateTime in JS. This is a polyfill for the Stage 3 
// TC39 proposal. Seems close enough to use
import { Temporal, Intl, toTemporalInstant } from '@js-temporal/polyfill';

Date.prototype.toTemporalInstant = toTemporalInstant;

/* Max weeks to go forward for time slots */
const maxWeeksForward               = process.env.REACT_APP_MAX_WEEKS_FORWARD

// used to filter times too near to now. This should come from the AppType but 
// can be zero. 
const leadTimeMinsDefault             = process.env.REACT_APP_LEAD_TIME_MINS;

const getWeekStartEnd = (dateTime) => {
    
    // take an instant and get a ZonedDateTime representing the day start
    const date      = dateTime.toZonedDateTimeISO('UTC').withPlainTime( Temporal.PlainTime.from({hour:0}) );
    const dow       = date.dayOfWeek - 1;
    const start     = date.subtract({days: dow});
    const end       = date.add({days: 7 - dow});
    
    return [start.toInstant(), end.toInstant()];
}

const parseDate = dateSt => {
    
    let result;
    try {
        result = Temporal.Instant.from( dateSt );
    }catch(e){
        // if we don't get a zone then assume it's in the London zone
        result = Temporal.PlainDateTime.from( dateSt ).toZonedDateTime(Temporal.TimeZone.from('Europe/London')).toInstant();
    }

    return result;
}

const TimeCapture = ({doUpdate, api, appState }) => {
    
    const { pageState, appointmentType} = appState;

    // user doen't want any time offered
    const noTimeAvalibleText            = appState.websiteConfig.NoTimeAvalibleText;
    const noTimeAvalibleLinkText        = appState.websiteConfig.NoTimeAvalibleLinkText;

    const numberOfRollingDays           = appState.websiteConfig.NumberOfRollingDays;

    const [amPmFilter, setAmPMFilter]   = useState('all');
    
    const [dateSlots, setDateSlots]     = useState([]);
    const [firstLoad, setFirstLoad]     = useState(true);
    
    /* the start of the current set of availability displayed or rather
     * the start of the current period requested from the server */
    const [startTime, setStartTime]     = useState(Temporal.Now.instant());
    const [forwardBack, setForwardBack] = useState(true); // start forward in time
    
    const [captured, setCaptured]       = useState(null);

    const buttonRef = useRef(null);

    // count the captured injuries
    const injuryCount                   = appState.injuries?.length  

    // get lead time from the app type
    const leadTimeMins = appointmentType?.LeadTimeMins ??
        leadTimeMinsDefault;

    const handleDateSelect = capturedValue => {

        buttonRef.current.focus?.();

        setCaptured(capturedValue);
    }

    useEffect( () => reloadBookingSlots(), [startTime, forwardBack, api, pageState] );
        
    const reloadBookingSlots = () => {
        
        if (pageState !== FormStateConsts.TimeCapture){
            // Don't reload on every state change, just when in TimeCapture
            return;
        }
        
        setCaptured(null);
        
        if(!appointmentType){
            doUpdate( {error: 'Error: We did\'t capture the appointment type'} );
            return;
        }

        const getBookingSlotsQuery = {
            AppointmentTypeID: appointmentType.AppointmentTypeID,
            Multiplier: injuryCount,
            IsVisual: appState.isVisual,
            ResultWithTimeZone: true
        }
        if (numberOfRollingDays) {
            // number of days can be forward or back (+, -)
            getBookingSlotsQuery['NumberOfDays'] = forwardBack? numberOfRollingDays: -numberOfRollingDays;
            getBookingSlotsQuery['StartTime']    = startTime;
        }else{
            const [start, end]  = getWeekStartEnd( startTime );
            getBookingSlotsQuery['StartTime'] = start;
            getBookingSlotsQuery['FinishTime'] = end;
        }

        api.transact('GetBookingSlots', getBookingSlotsQuery)
        .then(response => response.apiResult)
        //.then ( slots => slots.slice() ) 
        .then( slots => slots.map( s => { return { ...s, ...{ StartTime: parseDate( s.StartTime ), FinishTime: parseDate( s.FinishTime ) } } } ) )
        .then( slots => slots.filter( s => { 
            const now = Temporal.Now.instant();
            const dur = now.until( s.StartTime );
            const mins = dur.total({unit: 'minute'});
            return mins > leadTimeMins;
        } ) )
        .then( slots => slots.sort( (el1, el2) => Temporal.Instant.compare( el1.StartTime, el2.StartTime ) ) )
            // convert back to old date objects as that's what everything else is written for
        .then( slots => slots.map( s => { return { ...s, ...{ StartTime: new Date( s.StartTime.epochMilliseconds ), FinishTime: new Date( s.FinishTime.epochMilliseconds ) } } } ) )
        .then( slots => {
            doUpdate( { clearError: true } );
            setDateSlots(slots);
            if(firstLoad && 0 === slots.length){
                weekChange(true);
            }
            setFirstLoad(false);
        })
        .catch( error => {
            console.log('GetTeamSlots', error);
            const errMsg = new DataCapture();
            errMsg.error = error.apiText;
            doUpdate(errMsg);
        })
    };
    
    /*
     * The user doesn't want any of the appoinments available. The API might 
     * provide a link back to some other stage as with phio integration where
     * a link back to phio can be provided.
     */
    const handleNoTimeAvaiable = e =>{
        e.preventDefault();
        
        api.transact('GetReturnToCaseLink', {})
        .then( r => r.apiResult )
        .then( r => {
            if(r){
                window.location.href = r;
            }else{
                doUpdate( { changePageState: FormStateConsts.PersonCapture} );
            }
        })
        .catch( error => {
            const errMsg = new DataCapture();
            errMsg.error = error.apiText;
            doUpdate(errMsg);
        })
    }
    
    const handleUpdate = (e) => {
        
        holdBookingSlot(captured, appState, doUpdate);
    }
    
    /**
     * Navigate a week forward or back
     * @param {boolean} forwardBackward true for forward false for back
     * @returns {undefined}
     */
    const weekChange = ( forwardBackward ) => {
        
        let newStart;
        if(numberOfRollingDays){
            // Using rolling days, startTime is actually a date
            if(forwardBackward){
                // take the last day we previously retreived and go on one day
                newStart = 
                    dateSlots.find( (e,i) => (dateSlots.length - 1) == i )
                    ?.FinishTime
                    ?.toTemporalInstant()
                    ?.add({hours: 24})?? // don't want last day again
                    startTime;
            }else{
                // get first day we previously retreived and go back one day
                newStart = 
                    dateSlots.find( (e,i) => 0 == i )
                    ?.FinishTime
                    ?.toTemporalInstant()
                    ?.subtract({hours: 24})?? // don't want first day again
                    startTime;
            }
        }else{
            const days = forwardBackward? 7: -7;
            newStart = startTime.toZonedDateTimeISO('UTC')
                .add({days: days})
                .toInstant();
        }
        setStartTime(newStart?? startTime);
        setForwardBack(forwardBackward);
    }
    
    const datesDisplay = () => {
        
        const dayMs = 24 * 60 * 60 * 1000
        const dates = new Map()
        dateSlots.forEach( slot => { 
            
            const date = Math.floor( new Date( slot.StartTime ).getTime() / dayMs )
            
            if(!dates.has( date )){
                dates.set( date, [] )
            }
            dates.get( date ).push( slot )
        } )
        
        return [...dates.keys()].map( d => {
        
            const date = new Date(d * dayMs )
            const slots = dates.get(d)
            return (
                <DayDiary captured={captured} select={handleDateSelect} key={date}
                    date={date} daySlots={slots} amPmFilter={amPmFilter} /> 
            )
    } )
    }
    
    const confirmEnabled = null != captured;
    
    return (
        <>
            <h2 className="center">
                When is a good time for you?
            </h2>

            <p className="center">
                Please select a date and time that suits you
            </p>

            <div className="time-select__top-bar">
                <TimeAmPmFilter setAmPMFilter={setAmPMFilter} value={amPmFilter} />
                
                <TimeForwardBackward weekChange={weekChange} />
            </div>
            <div className="time-select__dates">
                {datesDisplay()}
            </div>

            <ButtonHolder parentRef={buttonRef} action={handleUpdate} actionTxt={'Confirm selection'}
                enabled={confirmEnabled} />

            {
                noTimeAvalibleLinkText && <p>
                    {noTimeAvalibleText} <br />
                    <a href='.' onClick={handleNoTimeAvaiable} > {noTimeAvalibleLinkText}</a>
                </p>
            }
                
                {appState.error}

            {false&&<UserOptions />}

        </>
    );
};

/* React Component to show user options which show at the bottom of the time 
capture page. 
Not using at the moment as just getting set to support simple use case
with no referal. Will start using again when there's a referral. */
const UserOptions = ({}) => {

    return (
        <>
            <div className="main__bottom">
                <div className="main__bottom-column">
                    No time in mind?<br />
                    Go directly to our <a href="./#" title="Referral form">referral form</a>
                </div>

                <div className="main__bottom-column">
                    Can't find an appointment that suits you? <br />
                    Look at <a href="./#" title="More options">more options</a>
                </div>
            </div>
        </>
    );
}

export default TimeCapture
