import ButtonHolder from "../ButtonHolder";

import React, { useState } from "react";

/**
 * REACT component to let a user select a number of injuries so that a further
 * component can capture them.
 * @param {function} setCount Callback to receive the count selected
 * @param {function} cancel Callback if the user cancels
 * @param {number} maxInjuries Maximum number of injuries that can be selected
 * @returns {JSX}
 */
const InjuryCount = ({ updateCount, cancel, maxInjuries = 3 }) => {

    const [count, setCount] = useState(0);

    const handleChange = event => {
        setCount(Number.parseInt(event.target.value));
    };

    const onOK = () => updateCount(count);

    return (
        <>
            <h2>Multiple Injury Areas?</h2>

            <Info />

            <div className="injury-areas__options">

                {[1,2,3,4,5,6,7,8,9,10]
                .filter( (i) => i <= maxInjuries )
                .map( (i) => <NumberCheckBox key={i} id={`item${i}`} label={i} value={i} onChange={handleChange} checked={i === count} /> )}

            </div>

            <ButtonHolder
                action={onOK}
                cancel={cancel}
                actionTxt={"Confirm and continue"}
                cancelTxt={"Cancel and go back"}
                enabled={"0" !== count}
            />
        </>
    );
};

const NumberCheckBox = ({ id, label, value, onChange, checked }) => {
    return (
        <label className="injury-areas__option" htmlFor={id}>
            <input
                className="injury-areas__option-input"
                type="radio"
                id={id}
                name="injury-areas"
                value={value}
                onChange={onChange}
                checked={checked}
            />

            <span className="injury-areas__option-content">{label}</span>
        </label>
    );
}

const Info = () => {
    const [showHide, setShowHide] = useState(false);

    const handleInfoClick = e => {
        e.preventDefault();

        setShowHide(!showHide);
    };

    const className = "highlight-block" + (showHide ? "" : " hidden");

    return (
        <>
            <p>
                To enable us to schedule the most suitable appointment for you,
                please confirm the number of injury areas for this booking
                <a href="#" className="modal__info" onClick={handleInfoClick}>
                    i<span className="more">nfo</span>
                </a>
            </p>

            <p className={className}>
                Related pains on the same side of the body, that occur together
                or start in one place and travel to another, can usually be
                treated as <strong>one&nbsp;injury&nbsp;region</strong>. If the
                patient is unsure, refer as{" "}
                <strong>multiple&nbsp;injury&nbsp;regions</strong>.
            </p>
        </>
    );
};

export default InjuryCount;
