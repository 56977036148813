import UnvalidatedForm from "./UnvalidatedForm";

/**
 * This is a legacy form which takes props including 
 * appState 
 * @param {*} props 
 * @returns 
 */
const Form = ({ appState, values, handleChange, api, validating}) => {

    const { websiteConfig: { ReferralForm: {Fields}, 
        formDataValues: formFieldData, DataSourceValues }, Client } = appState;

    const dataSources = DataSourceValues?.[Client?.ClientPartyID];

    // take out datasources identified by a datasourceID
    const fieldDefinitions = 
        Fields.map(f => ({...f, options: dataSources?.[f.DataSourceID]?? f.options}));

    const props = {validating, fieldDefinitions, formFieldData: values, api, onChange: handleChange};

    return <UnvalidatedForm {...props} />
}


export default Form;