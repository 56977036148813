import React from 'react'


/*
 * Componentg to show buttons to select between am / pm / both 
 * @param {type} value the current value('am','pm' or 'all')
 * @param {type} setAmPMFilter callBack for change
 */
const TimeAmPmFilter = ({value, setAmPMFilter}) => {
    
    const basicClass = 'time-select__button time-select__button--desktop'
    const amClass    = basicClass + ('am' === value? 'time-select__button--active': '')
    const pmClass    = basicClass + ('pm' === value? 'time-select__button--active': '')
    const allClass   = basicClass + ' time-select__button--wider ' + ('all' === value? 'time-select__button--active': '')
    
    const handleChange = e => {
        
        setAmPMFilter( e.target.name )
    }
    
    return (
    
        <div className="time-select__filter">
           
            <label className="time-select__filter-label">Filter:</label>

            <button name='am' className={amClass}   onClick={handleChange} >
                am
            </button>

            <button name='pm' className={pmClass}   onClick={handleChange} >
                pm
            </button>

            <button name='all' className={allClass} onClick={handleChange} >
                Show all
            </button>
        </div>
    )
}

export default TimeAmPmFilter