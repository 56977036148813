import { useEffect, useState } from "react"
import { TwoSwitch } from "../../lib/iprs-react-library/src";

const SelfReferralLink = ({api, caseID}) => {

    const [token, setToken] = useState("");

    useEffect(() => {
        api.transact("GetWelcomeToken",  {CaseID: caseID })
        .then( r => r.apiResult )
        .then( url => setToken(url) );
    }, [api, caseID]);

    const baseURL = process.env.REACT_APP_SELF_REFERRAL_BASE_URL;
    const url = (baseURL && token) ? `${baseURL}?Token=${token}`: null;

    return <TwoSwitch test={url} >
            <div>
                This link is similar to the self referral email we will
                send to the patient if this is setup for them:-<br/>
                <a target='_blank' href={url} >Self Referral Link</a></div>
        </TwoSwitch> 
}

export default SelfReferralLink;