import React, { useReducer, useCallback, useEffect, useState }
                                            from 'react';

import {bootstrap}                      
                                            from './BootstrapConfig';
import {
    AccountMenu, TwoSwitch,
    PageFoot, LoginOverlay
}
                                            from  "../../lib/iprs-react-library/src/index";
import DataCapture, {FormStateConsts}       
                                            from '../FormDesigner/Form/DataCapture';
import APPRender                            from './AppRender';

import                                           './App.css';

import reducer                              from './Reducer';
import SiteConfigSelect                     from '../FormDesigner/SiteConfigForm/SiteConfigSelect';
import FormProgress                         from './FormProgress';

const PUBLIC_URL                        = process.env.PUBLIC_URL;
const configMode          = true;

/**
 * This is the main config app. It is used to configure the booking site.
 * This has been created a copy of the main app so that the main app can
 * be separated from the config app over time.
 * @returns {String} Renderable JSX
 */
const ConfigApp = ({api}) => {
    
    const initialState          = new DataCapture();
    initialState.api            = api;
    initialState.configMode     = true;

    /* Store of the application state 
     * Note getReducer just gets a reducer function that is bound to the api */
    const [appState, doUpdate] = useReducer(reducer, initialState);

    /* Overall configuration data, including the list of all appointment types
     * and clients as well as all the currently setup website configuration
     * names and IDs */
    const [configData, setConfigData] = useState({});

    /* Cause the bootsstrap function to run whenever the state transitions
     * to 'Bootstraping' */
    useEffect( () => {
            if( !configMode && (FormStateConsts.Bootstraping === appState.pageState) ){
                bootstrap(api, doUpdate);
            }
        },
        [appState.pageState, api] 
    );

    const handleLoginEvent = useCallback((actionName) => {

        doUpdate({ handleLoginEvent: actionName });
    }, []);

    const handleFormProgClick = useCallback(e => {
        e.preventDefault();
        doUpdate({ changePageState: e.target.name });
    }, [doUpdate]);

    const formProgProps = {
        progress: appState.pageState,
        activeStages: appState.activeStages,
        handleClick: configMode ? handleFormProgClick : null
    };

    return <div className="App">
        <div className="typography">
            <div className="container">
                
                <main className="main">
                    <div className="container">
                        <header className="topHeader main__top">
                            <div className="main__top-logo">
                                <img src={PUBLIC_URL + '/images/iprshealth.svg'} alt="IPRS Health" />
                            </div>
                            <h1 className="main__top-title">
                                Welcome to IPRS Health's Services
                            </h1>
                            <AccountMenu api={api} handleLoginEvent={handleLoginEvent} />
                        </header>
                    </div>

                    <nav className="main">
                        <TwoSwitch test={configMode && api.isLoggedIn()} >
                            <SiteConfigSelect {...{ ...appState, doUpdate, api, configData, setConfigData }} />
                        </TwoSwitch>
                    </nav>

                    <FormProgress {...formProgProps} />

                    <section className="main__section">
                        <TwoSwitch test={!configMode || api.isLoggedIn()} >
                            <APPRender doUpdate={doUpdate} api={api} appState={{ ...appState, designMode: configMode, configData }} />
                            <div className="container typography login_container" >
                                <main>
                                    <LoginOverlay handleLoginEvent={handleLoginEvent} api={api} />
                                </main>
                            </div>
                        </TwoSwitch>
                    </section>
                </main>

                
            </div>
        </div>

        <PageFoot />
    </div>
}

export default ConfigApp;
