import React                                        from 'react';

/**
 * REACT component to diasplay an HTML select made up from objects 
 * that contain a title and numeric ID which are obtained with 
 * getItemTitle and getItemID respectively.
 * The setSelectedItem with the ID of the newly selected item when
 * the user selects a item.
 * @param {any} param0
 */
const SingleSelect = ({ selectableItems, selectedItem,
    setSelectedItem, getItemID, getItemTitle, name, disabled=false }) => {

    const handleChange = e => {

        e.preventDefault()

        setSelectedItem(Number.parseInt(e.target.value, 10))
    }

    const types = () => selectableItems.map(item => {
        
        const id = getItemID(item);
        return (<option key={id} value={id} >{getItemTitle(item)}</option>)
    }
    )

    return <select className="appointment-types" value={selectedItem} 
        disabled={disabled} onChange={handleChange} 
        style={{ float: 'left', marginRight: '5px' }}
        name={name ?? 'appointment-types'} id={name ?? 'appointment-types'} >
        {types()} 
    </select>
}

export default SingleSelect;