
import React, {useState} from 'react'

/**
 * Displays a select with options for all the different injury types
 * @param {Array} injuryTypes
 * @param {Integer} index
 * @param {function} onChange
 * @returns {undefined}
 */
const InjuryOptionList = ({injuryTypes, index, onChange, injuries, captureComments}) => {
    
    const [selected, setSelected] = useState(0);
    const [comment,  setComment]  = useState('');
    
    const items = 
        injuryTypes
        .filter( it => {
            
            const   isSel         = selected ===  it.pkRegionId;
            // does injuries include 'it'
            const   isInc         = injuries.reduce( ((accumulator, currentValue) => accumulator || (currentValue.pkRegionId === it.pkRegionId)), false );
            return  isSel || !isInc;
        })
        .map( it => <option key={it.pkRegionId} value={it.pkRegionId} >{it.NonMedicalRegion}</option>)
    
    const defaultitem =  <option key={0} value={0} >Select</option>
    
    const totItems = [defaultitem, ...items];
    
    const handleChange = e => {
        e.preventDefault()
        
        const newValue = { comment, pkRegionId: selected }
        if('comment' === e.target.name){
            newValue.comment = e.target.value;
            setComment(newValue.comment);
        }else{
            const pkRegionId = Number.parseInt( e.target.value, 10 );
            setSelected( pkRegionId );
            newValue.pkRegionId = pkRegionId;
        }
        
        onChange( index, newValue );
    }
    
    const showComment = (captureComments && selected)? true: false;
    
    const id = index + 1;

    return (
            <div>
            <div className="injury-areas__dropdown-holder">
                <label className="injury-areas__dropdown-label" htmlFor={'injury_select_'+id} >{id}</label>
                <div className="form__dropdown-wrapper">
                    <select 
                    id={'injury_select_'+id}
                    name='injuryID' value={selected} onChange={handleChange}  className="form__dropdown">{totItems}</select>
                </div>
            </div>
            {
                showComment && 
                <div className='injury-areas__dropdown-holder' >
                    <textarea className={'form__input'} placeholder='Please add comments on this injury' 
                        style={{clear: 'left'}} value={comment} name='comment' onChange={handleChange} ></textarea>
                </div>
            }
        </div>
    )
}

export default InjuryOptionList