import { getDateTimeStr } from  "../../lib/iprs-react-library/src/index";

/**
 * A function to hold a booking slot.
 */
const holdBookingSlot = (captured, appState, doUpdate) => {
    const { api } = appState;

    appState.startTime = captured[0];
    appState.endTime = captured[1];

    if (!appState.configMode) {
        api.transact("HoldBookingSlot", {
            AppointmentTypeID: appState.appointmentType.AppointmentTypeID,
            StartTime: getDateTimeStr(appState.startTime),
            FinishTime: getDateTimeStr(appState.endTime),
            IsVisual: appState.isVisual
        })
            .then(r => {
                doUpdate({
                    holdBookingID: r.apiResult.BookingID,
                    StartTime: captured[0],
                    FinishTime: captured[1]
                });
            })
            .catch(err => {
                doUpdate({ error: err.apiText });
            });
    }
};

export default holdBookingSlot;
