/* 
 * A react component to implement two HTML textBoxes. The design uses two 
 * boxes together, with specific styling which is easiest implemeented with 
 * a specific component that wrapps two boxes.
 * A longer term soltion is a wrapper that can simply wrap any components.
 */

const TwoTextBox = (props) => {
    
    const {field1 = {}, field2 = {}, values, handleChange, validating } = props;
    
    const value1 = values[field1?.name];
    const value2 = values[field2?.name];
    
    let className1 = 'form__input form__input form__input--mobile-y-bottom';
    let className2 = className1;
    if(validating){
        if( field1.required && !value1){
            className1 += ' input-validate-highlight'
        }
        if( field2.required && !value2){
            className2 += ' input-validate-highlight'
        }
    }
    
    return <div className={'form__field form__field--full-width'} >
        <label className="form__label" htmlFor={field1.name}>
            {field1.text}
            {field1.required && <span style={{color: 'red'}}> *</span>}
        </label>

        <input name={field1.name} type="text" className={className1} onChange={handleChange} value={value1} />

        <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={field2.name}>
            {field2.text}
            {field2.required && <span style={{color: 'red'}}> *</span>}
        </label>

        <input name={field2.name} type="text" className={className2} onChange={handleChange} value={value2} />
    </div>
}

export default TwoTextBox;