import React                from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App                  from './components/App/App';
import API from 'iprs-api-library';
// eslint-disable-next-line no-unused-vars 
import demoMockNetwork from './__test__/demoMockNetwork'; //(might use this again on this branch)
import { TwoSwitch } from './lib/iprs-react-library/src';
import ConfigApp from './components/App/ConfigApp';

const api = new API(null, process.env.REACT_APP_IPRS_WEB_API_URL);

// const appMockNetwork = (messageID, params) => {
//   const result = demoMockNetwork(messageID, params);
//   // if( messageID === 'GetBookingSiteConfig' ){
//   //     result.Result.ServiceActivities = extraServiceActivities;
//   // }
//   return result;
// }

//api.setAlternateNetworkConnectionFunction(appMockNetwork);

const isTrue = value => (value.toLowerCase() === 'true' || value.toLowerCase() === 'yes');
const configModeSettting  = isTrue( process.env.REACT_APP_BOOKING_SITE_CONFIG_MODE );

const PUBLIC_URL                        = process.env.PUBLIC_URL;
const configPath                        = PUBLIC_URL +
                                            process.env.REACT_APP_BOOKING_SITE_CONFIG_PATH;

/* the configuration app is now completely separate from the main app */
const configMode          = configModeSettting && configPath === window.location.pathname;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
      <TwoSwitch test={configMode} >
        <ConfigApp api={api} />
        <App api={api} />
        </TwoSwitch>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
