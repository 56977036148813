import React, {useState}     
                                                    from 'react';
import PlusButton, { OKCancel, MinusButton }        from '../util/Buttons';
import SingleSelect                                 from './SingleSelect.jsx';

/**
 * React component to display a selectable list of Appointment types. This is 
 * similar to the list displayed in the sidebar but with select buttons.
 * 
 * @returns {undefined}
 */
const MultiSelect = ({  selectableItems, 
                        selectedItems, 
                        setSelectedItems,
                        getItemID =     i=>i.id,
                        getItemTitle =  i=>i.Title,
                        defaultItemID = 0,
                        name='MultiSelect'}) => {

    if(!selectableItems){
        throw new Error('selectableItems cannot be empty in ' + name);
    }
    if(!selectedItems){
        throw new Error('selectedItems cannot be empty in ' + name);
    }
    
    const [selectedItem, setSelectedItem] =     
            useState(selectableItems.length? getItemID( selectableItems[0] ): defaultItemID );
    
    const ItemsListed     = selectableItems.filter( at =>  selectedItems.includes( getItemID( at ) ) )
    const selectableItemsNotListed = selectableItems.filter( at => !selectedItems.includes( getItemID( at ) ) )
    
    const handleAdd = e => {
        e.preventDefault();
        
        // don't try to add when nothing in list
        if(selectedItem){
        
            // this removes duplicates from the list
            const newSelectedItems           
                    = selectableItems.filter( at => selectedItem === getItemID( at ) || selectedItems.includes( getItemID( at ) ) )
                    .map( at => getItemID( at ) );
            /* so we can find something to select now that the previously 
             * selected item is no longer in the list */
            const newItemsNotListed  = selectableItems.filter( at => !newSelectedItems.includes( getItemID( at ) ) );

            setSelectedItems( newSelectedItems, name );

            if ( newItemsNotListed.length ) {
                setSelectedItem( getItemID( newItemsNotListed[0] ) );
            } else {
                /* nothing in the list to select */
                setSelectedItem( null );
            };
        }
    }
    
    const handleRemove = e => {
        
        const id = Number.parseInt( e.target.name, 10 );
        const newSelectedItems = [...selectedItems].filter( t => t !== id );
        
        setSelectedItems( newSelectedItems, name );
    }
    
    return (
        <div>
        <div id={name} name={name} >
            <SingleSelect selectableItems={selectableItemsNotListed} 
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem} 
                getItemID={getItemID}
                getItemTitle={getItemTitle} />
            <PlusButton onClick={handleAdd} name='addAppType' value={handleAdd} />
        </div>
        <ul className={'unstyled'} >
        {ItemsListed.map( a => <li key={ getItemID( a )}>{getItemTitle(a)}<MinusButton name={getItemID( a )} onClick={handleRemove} /></li> )}
        </ul>
        </div>
    )
}

export default MultiSelect