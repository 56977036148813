
const fieldNames =[
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'town',
    'county',
    'postcode'
];

const fieldPatterns = {
    addressLine1: /addr(\w*1|ess([\s]|$))/gi, // sometime this one is just somethingAddress
    addressLine2: /addr\w*2/gi,
    addressLine3: /addr\w*3/gi,
    town:         /town/gi,
    county:       /county/gi,
    postcode:     /code/gi
    };

/* find the best field option for each field so fielf with key WorkAddress1 
 * would map to field addressLine1 and addr2 would map to addressLine2 */
const bestField = (fieldKeys, fieldName) => {
    
    const regex = fieldPatterns?.[fieldName];
    
    const result = fieldKeys?.find( f => f.key.match( regex ) );
    
    return result;
}

/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
const AddressDesign = ({field, fieldOptions: fieldOptionsRaw, updateField}) => {
    
    const {addressLine1 = {}, addressLine2, town, county, postcode}
        = field;
    
    /* Bug in API means sometime we get an empty set */
    const fieldOptions      = fieldOptionsRaw.filter( fo => fo.length? true: false );
    
    const thisOptionKey        = fieldOptions.find( f => addressLine1.name === f[0].key )?.[0].key;
    
    /* Change in the selection of field. E.g a change from address to workAddress.
     * This involves all the sub fields changing 
     * e.g addressline2 becomes workaddressline2 */
    const handleFieldChange = e => {
        
        const newField = {...field};
        
        const fieldKeys = fieldOptions.find( f => e.target.value === f[0].key );
        fieldNames.forEach( f => {
            const matchKeys = bestField(fieldKeys,f);
            const subField = newField[ f ]? newField[ f ]: {};
            const newSubField = {...subField};
            if(matchKeys?.key){
                newSubField.name = matchKeys.key;
            }
            if(matchKeys?.defaultText){
                newSubField.text = matchKeys.defaultText;
            }
            newField[ f ]  = newSubField;
        } );
        
        updateField( newField );
    }
    
    const handleChange = ( e, subFieldKey) => {
        
        e.stopPropagation();
        
        const { target: { name, checked, value } }  = e;
        
        const newField = {...field};
        if ('keyName' === name){
            
        }else { 
            const subField  = newField[subFieldKey]?? {};
            if( e.target.hasOwnProperty('checked') ){
                subField.required   = checked;
            }else{
                subField.text       = value;
            }
            newField[subFieldKey] = {...subField};
        }
        
        updateField( newField );
    }
    
    const updateFieldAddressLine1   = e => handleChange(e, 'addressLine1');
    const updateFieldAddressLine2   = e => handleChange(e, 'addressLine2');
    const updateFieldTown           = e => handleChange(e, 'town');
    const updateFieldCounty         = e => handleChange(e, 'county');
    const updateFieldPostcode       = e => handleChange(e, 'postcode');
    
    return <div>
    
        <FieldComp  field={addressLine1} updateField={updateFieldAddressLine1}  label='Address Line 1 Label' />
        <FieldComp  field={addressLine2} updateField={updateFieldAddressLine2}  label='Address Line 2 Label' />
        <FieldComp  field={town}         updateField={updateFieldTown}          label='Town Label' />
        <FieldComp  field={county}       updateField={updateFieldCounty}        label='County Label'/>
        <FieldComp  field={postcode}     updateField={updateFieldPostcode}      label='Postcode Label'/>
        
        <FieldSelect fieldOptions={fieldOptions} thisOptionKey={thisOptionKey} handleChange={handleFieldChange} />
    </div>
};

const FieldComp = ({field, updateField, label}) => {
    
    return <div className={'form__field form__field--full-width'} >
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor={'text'}>
                {label}
            </label>
            <input name={'text'} value={field?.text}   placeholder={label}    onChange={updateField} className='form__input form__input form__input--mobile-y-bottom'/>
            <label class="form__label form__label--auto-width form__label--margin-x" htmlFor='required' >
                Required
            </label>
            <input name={'required'} checked={field?.required}  onChange={updateField}  type='checkbox' />
        </div>

}

const FieldSelect = ({fieldOptions, thisOptionKey, handleChange}) => {
    
    return <div className={'form__field form__field--full-width'} >
            <label htmlFor='keyName'>Key Name</label>
            <select name='keyName' value={thisOptionKey} onChange={handleChange}  >
            { fieldOptions
                    ?.map( fo => fo[0] ) // text field has no sub fields
                    ?.map( (fo) => <option value={fo.key} >{fo.defaultText} ({fo.key})</option> )
            }
            </select>
            
        </div>
}

export default AddressDesign;
