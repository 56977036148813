import React                from 'react';

const InfoBox = ({ subFields = [] }) => {
    
    const render =  renderSubFields( subFields );
    
    return React.createElement( 'div', null, render );
}

const renderSubFields = ( subFields ) => {
    
    let inList = false;
    
    let currentList = [];
    
    const output = [];
    
    subFields.forEach( (subField, index) => {
        
        const { text, href, type }                  = subField;
        
        const props = ('a' === type)? { href }: {};
        output.push( React.createElement(type, props, text) );
        
        let typeChange = ( inList && 'li' !== type ) || ( !inList && 'li' === type );
        
        if( typeChange || ( subFields.length - 1 ) === index ){
            if( currentList.length ){
                const comp = React.createElement(
                    inList? 'ul': 'p',
                    null,
                    currentList
                );
                output.push( comp );
                currentList = [];
                inList = false;
            }
        }
    } );
    
    return output;
}

export default InfoBox;