import React, { useState } from "react";
import { fieldToComponent, fieldToDesignComponent } from "./Form/Form";
import { TwoSwitch } from  "../../lib/iprs-react-library/src/index";
import { Draggable } from "react-beautiful-dnd";

const fieldTypes = [
    ["address", "Postal Address"],
    ["heading", "Heading"],
    ["twotextbox", "Double TextBox"],
    ["dropdownlist", "Drop-Down Select"],
    ["multiselect", "Multiple Checkboxes"],
    ["datetextbox", "Date Box"],
    ["break", "Selection Break"],
    ["text", "Text Box"],
    ["checkbox", "Checkbox"],
    ["email", "Email"],
    ["tel", "Telephone"],
    ["info", "Info Box"]
];

const optionsForControlType = (fieldOptions, controlType) => {
    let options;
    if (
        "twotextbox" === controlType ||
        "checkbox" === controlType ||
        "email" === controlType ||
        "tel" === controlType
    ) {
        options = fieldOptions["text"];
    } else if ("dropdownlist" === controlType) {
        options = [...fieldOptions["text"]];
        // Only do this if fieldOptions['dropdownlist'] has values. Otherwise it blows up
        if (fieldOptions["dropdownlist"]) {
            options = [...options, ...fieldOptions["dropdownlist"]];
        }
    } else if ("multiselect" === controlType) {
        options = fieldOptions["multiselect"];
    } else {
        options = fieldOptions[controlType];
    }

    return options;
};

/*
 * Component to wrap a form field in a draggable area and to add some features
 * for moving form inputs around.
 */
const DesignField = ({ field, index, websiteConfig, values, updateField }) => {
    const { fieldOptions } = websiteConfig;

    const [editing, setEditing] = useState(false);

    /* An update function that updates just this field. This avoids the config
     * component needing to know the index */
    const updateThisField = newField => updateField(newField, index);

    /* The options for just this field. Options are the names of fields with
     * suggested label text */
    const thisFieldOptions = optionsForControlType(
        fieldOptions,
        field.controlType
    );

    const handleDelete = () => {
        updateField(null, index, true);
    };

    return (
        <Draggable draggableId={field.name} index={index}>
            {provided => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "gray",
                            margin: "10px"
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "lightGray",
                                margin: "10px",
                                flex: "4",
                                minHeight: "5em"
                            }}
                        >
                            <TwoSwitch test={editing}>
                                <div style={{ padding: "10px" }}>
                                    <TypeSelect
                                        field={field}
                                        updateField={updateThisField}
                                    />
                                    {React.createElement(
                                        fieldToDesignComponent(field),
                                        {
                                            field,
                                            fieldOptions: thisFieldOptions,
                                            updateField: updateThisField
                                        }
                                    )}
                                </div>

                                <div style={{ padding: "20px" }}>
                                    {React.createElement(
                                        fieldToComponent(field),
                                        { ...field, values }
                                    )}
                                </div>
                            </TwoSwitch>
                        </div>
                        <div
                            style={{
                                backgroundColor: "white",
                                margin: "10px",
                                flex: "1"
                            }}
                        >
                            <button
                                style={{ float: "right" }}
                                onClick={e => {
                                    setEditing(!editing);
                                }}
                            >
                                {editing ? "Preview" : "Edit"}
                            </button>
                            <button
                                style={{ float: "right", clear: "right" }}
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const TypeSelect = ({ field, updateField }) => {
    const handleChange = e => {
        e?.stopPropagation();
        e?.preventDefault();

        const newField = { ...field };
        newField.controlType = e.target.value;

        updateField(newField);
    };

    return (
        <div>
            <label>FieldType</label>
            <select
                name="typeSelect"
                value={field.controlType.toLowerCase()}
                onChange={handleChange}
            >
                {fieldTypes.map(ft => (
                    <option key={ft[0]} name={ft[0]} value={ft[0]}>
                        {ft[1]}
                    </option>
                ))}
            </select>
        </div>
    );
};

export { TypeSelect };
export default DesignField;
