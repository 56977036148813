import React                                            from 'react';

const PageFoot = () => {
    
    return (
        <div className="container">
        <div className="typography">
            <footer className="footer">
                <p className="footer__content">
                    &copy; 2021-2023 IPRS Health Limited, Speed Medical House, 
                    Chorley, Lancashire PR7 1NA. Registered in England and Wales (03015659).
                    <span className="footer__delimiter">|</span>  
                    <a href="./#" title="Privacy Policy">Privacy Policy</a>
                    </p>
                    {process.env.REACT_APP_VERSION && <p style={{ display: 'none' }}>{process.env.REACT_APP_VERSION}</p>}
            </footer>
        </div>
    </div>
    )
}

export default PageFoot