import {useState, useEffect}                    from 'react'; 
import {MultiSelect}                            from  "../../../lib/iprs-react-library/src/index";

const SiteConfigForm = (appState) => {
    
    const {
        websiteConfig = {},  // the config for the currently selected site
        configData: { 
            AllClients = [], 
            AllAppointmentTypes,
            AllServicesActivities = [] },
        doUpdate,
        api
    } = appState;
    
    const {
        AppointmentTypes = [],
        Clients = []
    } = websiteConfig;

    // the MultiSelect component needs a list of IDs not objects
    const selectedAppTypes = AppointmentTypes.map( a => a.AppointmentTypeID );
    const selectedClients  = Clients.map( c => c.ClientPartyID );
    
    /* handle user input from the siteForm. This includes changes in the 
     * appointment types that this site manages and similarly the clients */
    const handleSiteUpdate = (e, v) => {
        
        if (e.preventDefault && !e.target.hasOwnProperty('checked') ){
            e.preventDefault();
        }
            
            const newSite = {...websiteConfig};
            if ('AppTypeSelect' === v){
                console.log('AppTypeSelect', e);
                newSite.AppointmentTypes = AllAppointmentTypes.filter( a => e.includes( a.AppointmentTypeID ) );
            }else if ('ClientSelect' === v){
                console.log('ClientSelect', e);
                newSite.Clients = AllClients.filter( c => e.includes( c.ClientPartyID ) );
            }else if (e.target.hasOwnProperty('checked')) {
                newSite[e.target.name] = e.target.checked;
            }else if ( 'ServiceActivitySelect' === e?.target?.name ){
                const ServiceActivityId = Number.parseInt( e?.target?.value );
                newSite.ServiceActivityId = ServiceActivityId;
                const service = AllServicesActivities.find( s => ServiceActivityId === s.ServiceActivityId );
                newSite.ServiceID = service?.ServiceID;
            }else{
                newSite[e.target.name] = e.target.value;
            }
            doUpdate({websiteConfig: newSite});
        
    };
    
    const clickHandler = e => {
        e.preventDefault();
        
        const referralObject = {
            DocType:        'IPRS Referral Form',
            TypeVersion:    '1.0'
        }
        
        const saveSite = {...websiteConfig};
        saveSite.AppointmentTypes = saveSite.AppointmentTypes.map( at => at.AppointmentTypeID );
        referralObject.Fields = saveSite.ReferralForm;
        saveSite.ReferralFormJSON = JSON.stringify( referralObject );
        api.transact('SaveBookingSiteConfig', saveSite)
            .then( r => r.apiResult )
            .then( r => doUpdate({websiteConfig: r} ) )
           .catch(err=>console.log('Error', err.apiText));
        
        doUpdate({saveConfiguration: true});
    }
    
    const hrStyle = {clear: 'both', marginBottom: '5em'};
    
    return <div>
        
        <form>
            <h2 style={{ marginBottom: '1em', fontSize: '1.5em' }} >Site Configuration</h2>

            <button onClick={clickHandler} >Save</button>

            <p>Change settings on this page to configure any of the booking and 
                combined booking and referral sites.</p>
            
            <hr style={hrStyle} />
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Booking Site Name</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.Title} name='Title' />
            </div>
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Booking Site URL</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.BookingURL} name='BookingURL'/>
            </div>
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Guest Token Time (Mins)</label>
                <input type='integer' value={websiteConfig.GuestTokenTimeMins} onChange={handleSiteUpdate} name='GuestTokenTimeMins' />
                <br />
                <p>Use zero for no guest access</p>
            </div>
            
            
            <hr style={hrStyle} />
            
            <div className='form__field form__field--full-width' >
                <label className="form__label" htmlFor='siteListSelect' >
                    Select Service activity code
                </label>
                <div className='form__dropdown-wrapper form__field form__field--full-width' >
                    <select className="form__dropdown" onChange={handleSiteUpdate} value={websiteConfig.ServiceActivityId} name='ServiceActivitySelect' >
                            {AllServicesActivities.map(s=><option  key={s.ServiceActivityId} value={s.ServiceActivityId} className='form__mock-dropdown-option' >{s.ServiceActivityName}</option>)}
                    </select>
                </div>
            </div>
            
            <hr style={hrStyle} />
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Select Clients</label>
            
                <MultiSelect selectableItems={AllClients}
                    selectedItems={selectedClients} 
                    setSelectedItems={handleSiteUpdate} 
                    getItemID={client=>client.ClientPartyID}
                    getItemTitle={client=>client.FriendlyName}
                    defaultItemID={0}
                    name='ClientSelect' />
            </div>
            <p>Only select one unless you want an option screen for this.</p>
            
            <hr style={hrStyle} />
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Select Appointment Types</label>
                <MultiSelect selectableItems={AllAppointmentTypes}
                    selectedItems={selectedAppTypes} 
                    setSelectedItems={handleSiteUpdate} 
                    getItemID={appType=>appType.AppointmentTypeID}
                    defaultItemID={0}
                    name='AppTypeSelect'/>
            </div>
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >NumberOfRollingDays</label>
                <input type='number' step='1' max='15' min='0'  
                    value={websiteConfig.NumberOfRollingDays?? 0} onChange={handleSiteUpdate} 
                    name='NumberOfRollingDays' id='NumberOfRollingDays' />
            </div>
            <p>Leave zero to have avaiability displayed in weeks. When set the number of days
                availability will be displayed.</p>

            <div className="form__field form__field--full-width" >
                <label className="form__label" >Capture Injuries</label>
                <input type='checkbox' checked={websiteConfig.CaptureInjuries} onChange={handleSiteUpdate} name='CaptureInjuries' />
            </div>
            <p>Choose whether injury regions can be selected</p>
            
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Capture Injury Comments</label>
                <input type='checkbox' checked={websiteConfig.CaptureInjuryComments} onChange={handleSiteUpdate} name='CaptureInjuryComments' />
            </div>
            <p>Choose whether comments can be added against injuries captures</p>

            <div className="form__field form__field--full-width" >
                <label htmlFor='CaptureVisual' className="form__label" >Offer Visual checbox</label>
                <input type='checkbox' checked={websiteConfig.CaptureVisual} onChange={handleSiteUpdate} name='CaptureVisual' id='CaptureVisual' />
            </div>
            <p>Choose whether to offer the Visual flag</p>

            <hr style={hrStyle} />
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Find by URL</label>
                <input type='checkbox' checked={websiteConfig.FindByURL} onChange={handleSiteUpdate} name='FindByURL' />
            </div>
            <p>Select if this site can be located by its URL.</p>
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Find by Service Activity</label>
                <input type='checkbox' checked={websiteConfig.ServiceActivity} onChange={handleSiteUpdate} name='ServiceActivity' />
            </div>
            <p>Select if this site can be located by its Service Activity Code.</p>
            
            <hr style={hrStyle} />
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Notification Options</label>
                <input type='checkbox' checked={websiteConfig.ShowNotificationOptions} onChange={handleSiteUpdate} name='ShowNotificationOptions' />
            </div>
            
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Appointment Type Review</label>
                <input type='checkbox' checked={websiteConfig.ShowAppTypeReview} onChange={handleSiteUpdate} name='ShowAppTypeReview' />
            </div>
            
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Show Patient Review</label>
                <input type='checkbox' checked={websiteConfig.ShowPatientReview} onChange={handleSiteUpdate} name='ShowPatientReview' />
            </div>
            
            <hr style={hrStyle} />

            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Live Help</label>
                <input type='checkbox' checked={websiteConfig.ShowLiveHelp} onChange={handleSiteUpdate} name='ShowLiveHelp' />
            </div>
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Live Help URL</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.LiveHelpURL} name='LiveHelpURL'/>
            </div>
            <p>Only needed if using Live Help</p>
            
            <hr style={hrStyle} />
            
             <div className="form__field form__field--full-width" >
                <label className="form__label" >Location Text</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.LocationText} name='LocationText'/>
            </div>
                 
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Confirmation Text</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.ConfirmationText} name='ConfirmationText'/>
            </div>
            
            <hr style={hrStyle} />
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Book-Another Link</label>
                <input type='checkbox' checked={websiteConfig.ShowBookAnotherLink} onChange={handleSiteUpdate} name='ShowBookAnotherLink' />
            </div>
            
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Add To Diary Link</label>
                <input type='checkbox' checked={websiteConfig.ShowAddToDiaryLink} onChange={handleSiteUpdate} name='ShowAddToDiaryLink' />
            </div>
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Get Directions Link</label>
                <input type='checkbox' checked={websiteConfig.ShowGetDirectionsLink} onChange={handleSiteUpdate} name='ShowGetDirectionsLink' />
            </div>
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Appstore Links</label>
                <input type='checkbox' checked={websiteConfig.ShowAppstoreLinks} onChange={handleSiteUpdate} name='ShowAppstoreLinks' />
            </div>
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Portal Links</label>
                <input type='checkbox' checked={websiteConfig.ShowPortalLinks} onChange={handleSiteUpdate} name='ShowPortalLinks' />
            </div>
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Show Feedback Stars</label>
                <input type='checkbox' checked={websiteConfig.ShowFeedbackStars} onChange={handleSiteUpdate} name='ShowFeedbackStars' />
            </div>

            <hr style={hrStyle} />

            <div className="form__field form__field--full-width" >
                <label className="form__label" >No Time Avalible Text</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.NoTimeAvalibleText} name='NoTimeAvalibleText' />
            </div>

            <div className="form__field form__field--full-width" >
                <label className="form__label" >No Time Avalible Link Text</label>
                <StatefulInput onChange={handleSiteUpdate} initialValue={websiteConfig.NoTimeAvalibleLinkText} name='NoTimeAvalibleLinkText' />
            </div>
            <p>Leaving this blank will show no skip link. </p>

            <hr style={hrStyle} />
            <p>Make sure that this should be used for the client, before
            turning this on. When turned on, the case will be sent to
            Phio when the user clicks the link to skip/go back. </p>
            <p>It would be a data breach to send data to Phio, for the wrong client.</p>
            <div className="form__field form__field--full-width" >
                <label className="form__label" >Phio Integration</label>
                <input type='checkbox' checked={websiteConfig.PhioIntegration} 
                       onChange={handleSiteUpdate} name='PhioIntegration' />
            </div>
            
            <button onClick={clickHandler} >Save</button>
        </form>
        
    </div>;
}

const StatefulInput = ({initialValue,onChange,name}) => {
    
    const [text, setText]                   = useState(initialValue?initialValue:'');
    
    useEffect( () => { setText( initialValue ) }, [initialValue] );
    
    const handleChange = e => {
        e.preventDefault();
        setText(e.target.value);
    }
    
    const handleBlur = e => {
        onChange( { preventDefault: () => {}, target: { name: name, value: text } } );
    }
    
    return <input onChange={handleChange} onBlur={handleBlur} className='form__input' value={text} name='Title'/>
} 

export default SiteConfigForm;