
const F2FSelfReferralServiceAct = 2067;
const HybridTriageServiceAct = 2011;

const VitalityHealthStaffSchemeClient = 191405;
const VitalityHealthClient = 191404;

/**
 * This function is used to alter the web config for WR527.
 * It adds config that we don't currently have in the database, as 
 * it's very client specific.
 * @param {*} webConfig 
 * @returns 
 */
const wr527Fudges = webConfig => {

    const fudge = {
        AppointmentTypeRestrictions: [
            {
                ServiceActivityID: F2FSelfReferralServiceAct,
                IncludeAppointmentTypeIDs: []
            }
        ],
        InjuryRegionRestrictions: {
            MaxNumberOfInjuries: 3,
            Restrictions: [
                {
                    ServiceActivityID: F2FSelfReferralServiceAct,
                    MaxNumberOfInjuries: 1
                }
            ]
        },
        ServiceActivities: [
            {
                ClientPartyIDs: [VitalityHealthClient, VitalityHealthStaffSchemeClient],
                ServiceActivityID: HybridTriageServiceAct,
                FriendlyName: "Hybrid Call"
            },
            {
                ClientPartyIDs: [VitalityHealthClient, VitalityHealthStaffSchemeClient],
                ServiceActivityID: F2FSelfReferralServiceAct,
                FriendlyName: "F2F Treatment - only use where a member has already had a triage call by another physiotherapy provider"
            }
        ]
    };

    return {
        ...webConfig,
        ...fudge
    };
};

export default wr527Fudges;
