import AppTypeList from "./AppTypeList";
import ButtonHolder from "../ButtonHolder";
import InjuryCapture from "./InjuryCapture";
import DataCapture from "../FormDesigner/Form/DataCapture";

import React, { useState, useEffect, useCallback } from "react";
import VisualChoice from "./VisualChoice";

const AppointmentCapture = ({ appState, doUpdate, api }) => {
    const [injuryOverlayActive, setInjuryOverlayActive] = useState(false);
    const [appointmentType, setAppointmentType] = useState(0);

    const [isVisual, setisVisual] = useState(null);

    const {
        AppointmentTypes: appointmentTypes,
        CaptureInjuries,
        MaxInjuries,
        CaptureInjuryComments: captureComments,
        CaptureVisual
    } = getRelevantConfig(appState);

    const updateInjuries = useCallback(
        (injuries, isVisual) => {
            //find the appointment data (description etc)
            const appTypeData = appointmentTypes.filter(
                t => t.AppointmentTypeID === appointmentType
            )[0];

            const appointmentTypeOb = new DataCapture();
            appointmentTypeOb.appointmentType = appTypeData;
            //appointmentTypeOb.appointmentTypes      = appTypeData
            appointmentTypeOb.injuries = injuries;

            appointmentTypeOb.isVisual = isVisual;

            doUpdate(appointmentTypeOb);
        },
        [appointmentType, appointmentTypes, doUpdate]
    );

    const updateVisual = vis => {

        console.log("updateVisual", vis);

        setisVisual(vis);

        if (CaptureInjuries) {
            setInjuryOverlayActive(true);
        } else {
            // if we aren't capturing injuries then just proceed with none
            updateInjuries([], vis);
        }
    };

    const doAction = useCallback(() => {

        if (!CaptureVisual || null != isVisual) {
            if (CaptureInjuries) {
                setInjuryOverlayActive(true);
            } else {
                // if we aren't capturing injuries then just proceed with none
                updateInjuries([]);
            }
        }
    }, [CaptureInjuries, CaptureVisual, isVisual, updateInjuries]);

    useEffect(() => {
        /* If we only have one appointment type then jump strait to asking about
         * the injury regions */
        if (1 === appointmentTypes.length) {
            setAppointmentType(appointmentTypes[0].AppointmentTypeID);

            doAction();
        }else if(0 === appointmentTypes.length){
            setAppointmentType(0);

            doAction();
        }
    }, [appointmentTypes, doAction]);

    const doCancel = () => {
        setInjuryOverlayActive(false);
        setisVisual(null);
    };

    const buttonEnabled = appointmentType;

    const catchAT = at => {
        setAppointmentType(at);
    };

    return (
        <>
            <form>
                <h2 className="center">
                    Select one of IPRS Health's available appointment types
                    below
                </h2>
                <AppTypeList
                    setAppointmentType={catchAT}
                    appointmentType={appointmentType}
                    appointmentTypes={appointmentTypes}
                />

                {CaptureVisual && null == isVisual && (
                    <VisualChoice {...{ updateVisual, doCancel }} />
                )}
                {injuryOverlayActive ? (
                    <InjuryCapture
                        update={updateInjuries}
                        cancel={doCancel}
                        api={api}
                        captureComments={captureComments}
                        maxInjuries={MaxInjuries}
                    />
                ) : (
                    ""
                )}

                <ButtonHolder
                    action={doAction}
                    actionTxt={"Book an Appointment"}
                    enabled={buttonEnabled}
                />
            </form>
        </>
    );
};

const getRelevantConfig = appState => {
    const {
        websiteConfig: { AppointmentTypes, CaptureInjuries ,
        InjuryRegionRestrictions,
        AppointmentTypeRestrictions,
        CaptureInjuryComments,
        CaptureVisual} ,
        serviceActivityId: ServiceActivityId,
        Client: {ClientPartyID: ClientId } 
    } = appState;

    const maxInjuriesInitial = CaptureInjuries
        ? (InjuryRegionRestrictions?.MaxNumberOfInjuries ?? 3)
        : 0;

    const InjuryRegionRestrictionsArray =
        InjuryRegionRestrictions?.Restrictions;

    /* match injury region restrictions to client and service activity 
    to find restrictions on the number of injuries to capture */
    const processInjuryRegionRestrictions = (
        maxInjuries,
        InjuryRegionRestrictionsArray,
        ClientId,
        ServiceActivityId
    ) => {

        let score = 0;
        let bestMatch = null;

        InjuryRegionRestrictionsArray?.forEach(element => {
            let thisScore = 0;
            if((element.ClientID && element.ClientID !== ClientId) || 
                (element.ServiceActivityID && element.ServiceActivityID !== ServiceActivityId))  {
                thisScore = -1;
            }else{
                if (element.ClientId && element.ClientId === ClientId) {
                    thisScore += 1;
                }
                if (
                    element.ServiceActivityID &&
                    element.ServiceActivityID === ServiceActivityId
                ) {
                    thisScore += 1;
                }
            }

            if (thisScore > score) {
                score = thisScore;
                bestMatch = element;
            }
        });
        const result = bestMatch ? bestMatch.MaxNumberOfInjuries : maxInjuries;

        return result;
    };

    /* match appointment type restrictions to client and service activity */
    const processAppointmentTypes = (appTypes, appTypeRestrictions, ClientId, ServiceActivityId) => {
        
        let score = 0;
        let bestMatch = null;

        appTypeRestrictions?.forEach(element => {
            let thisScore = 0;
            if (element.ClientId && element.ClientId === ClientId) {
                thisScore += 1;
            }
            if (
                element.ServiceActivityID &&
                element.ServiceActivityID === ServiceActivityId
            ) {
                thisScore += 1;
            }
            if (thisScore > score) {
                score = thisScore;
                bestMatch = element;
            }
        });
        const result = bestMatch
            ? appTypes.filter(t =>
                  bestMatch.IncludeAppointmentTypeIDs.includes(t.AppointmentTypeID)
              )
            : appTypes;

        return result;
    };

    const MaxInjuries = InjuryRegionRestrictionsArray
        ? processInjuryRegionRestrictions(
              maxInjuriesInitial,
              InjuryRegionRestrictionsArray,
                ClientId,
                ServiceActivityId
          )
        : maxInjuriesInitial;

    const appointmentTypes = AppointmentTypeRestrictions
        ? processAppointmentTypes(AppointmentTypes, AppointmentTypeRestrictions,ClientId,
            ServiceActivityId)
        : AppointmentTypes;

    return {
        AppointmentTypes: appointmentTypes,
        CaptureInjuries: MaxInjuries > 0,
        MaxInjuries,
        CaptureInjuryComments,
        CaptureVisual,
        ServiceActivityId
    };
};

export default AppointmentCapture;
